import React from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable from 'material-table';
import { jQGridService, roleService } from '../../../_services';
import { tableIcons } from './tableIcons';

import { formType } from '../../../_constants';
import palettle from '../../../theme/palette';
import { default as actionList } from '../../button/materialActionButton';

const StdDatatable = (props) => {
  const {
    t,
    title,
    columns,
    setSelected,
    setFormOpen,
    setFormType,
    menuCode,
  } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const [state, setState] = React.useState({ isCancelled: false });

  const role = user.roleinfo; // {code, description}
  var addAction = actionList.addAction((e) => {
    setFormOpen(true);
    setFormType(formType.INSERT);
  });
  var viewAction = actionList.viewAction((e, rowData) => {
    e.preventDefault();
    setSelected(rowData.Code);
    setFormOpen(true);
    setFormType(formType.VIEW);
  });

  var editAction = actionList.editAction((e, rowData) => {
    e.preventDefault();
    setSelected(rowData.Code);
    setFormOpen(true);
    setFormType(formType.UPDATE);
  });

  var deleteAction = actionList.deleteAction((e, rowData) => {
    e.preventDefault();
    const confirm = window.confirm(
      `you want to delete this document no. ${rowData.Code} ?`,
    );
    if (confirm) {
      setSelected(rowData.Code);
      setFormOpen(true);
      setFormType(formType.DELETE);
    }
  });

  React.useEffect(() => {
    var isCancelled = false;
    roleService.getRolePermissions(role.code).then((res) => {
      if (!isCancelled) {
        var menuPermission = res.data.results.find(
          (o) => o.menuCode === menuCode,
        );
        var actionList = [];
        if (menuPermission.new_permission) {
          actionList.push(addAction);
        }
        if (menuPermission.edit_permission) {
          actionList.push(editAction);
        }
        if (menuPermission.view_permission) {
          actionList.push(viewAction);
        }
        if (menuPermission.delete_permission) {
          actionList.push(deleteAction);
        }
        setActions(actionList);
      }
    });

    return () => {
      setState({ isCancelled: true });
      isCancelled = true;
    };
  }, []);
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        actions={actions}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters = query.filters;
            filters = filters.map((el) => {
              return {
                title: el.column.title,
                field: el.column.field,
                operator: el.operator,
                value: el.value,
              };
            });
            try {
              if (!state.isCancelled) {
                jQGridService
                  .search(
                    props.filenameXML + '.xml',
                    query.page + 1,
                    query.pageSize,
                    query.search,
                  )
                  .then((res) => {
                    if (!!res.data) {
                      resolve({
                        data: res.data.rows,
                        page: res.data.page - 1,
                        totalCount: res.data.records,
                      });
                    } else {
                      resolve({
                        data: [],
                        page: query.page,
                        totalCount: query.totalCount,
                      });
                    }
                  });
              }
            } catch (error) {
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          // filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },

          pageSize: 10,
          pageSizeOptions: [5, 10, 100],
        }}
        title={title}
      />
    </>
  );
};

export default translate(StdDatatable);
