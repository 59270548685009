import React from 'react';
import { translate } from 'react-switch-lang';
import { TransactionDatatable } from '../../_fragments/transactiondatatable';
import { CustomerService as service } from '../../_services/customer.service';
import { Label } from 'semantic-ui-react';

import moment from 'moment';
const Customer = (props) => {
  const { t } = props;
  const header = {
    icon: 'user',
    content: 'Customer',
    subHeader: 'Listing',
  };
  const tableRef = React.createRef();

  const insertBtn = {
    text: t('Add'),
    link: props.location.pathname + '/insert',
  };

  const actionPath = {
    add: `${props.location.pathname}/insert`,
    edit: `${props.location.pathname}/update`,
    delete: `${props.location.pathname}/delete`,
  };

  const headers = [
    { title: 'Code', field: 'Code' },
    { title: 'Name', field: 'Name' },
    { title: 'Active', field: 'Active' },
    { title: 'CreateDate', field: 'CreateDate' },
    { title: 'CreateBy', field: 'CreateBy' },
  ];

  const title = 'Customer';
  const filenameXML = 'Customer';
  const menuCode = 'M_CUSTOMER';

  return (
    <>
      <TransactionDatatable
        header={header}
        insertBtn={insertBtn}
        title={title}
        headers={headers}
        filenameXML={filenameXML}
        root={props.root}
        history={props.history}
        path={actionPath}
        service={service}
        menuCode={menuCode}
        tableRef={tableRef}
      />
    </>
  );
};

export default translate(Customer);
