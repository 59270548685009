import { assetConstants as constants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import { postRequest, getRequest, postRequestSync } from '../_helpers';
import { flatMapDeep } from 'lodash';
import uuid from 'react-uuid';
import { getRefs, getWarrantyPeriodUOM } from './refs/assetRef.service';
import moment from 'moment-timezone';
import axios from 'axios';

async function insertItem(item) {
  var warranty;
  var license;
  var isError = false;
  var war;
  var lc;
  // if (item.warantyStart) {
  //   warranty = createWarranty(item);

  // }
  // if (item.licenseStart) {
  //   console.log('created license: ', license);
  //   license = await insertLicense(item);

  // }
  console.log('Inserting !!!');
  if (item.warrantyStart === false && item.licenseStart != false) {
    console.warn('Warranty Missing!!!');
    return await insertLicense(item)
      .then((res) => {
        console.log('license only: ', res);
        return postRequest(
          constants.API_INSERT,
          toApi({ ...item, license: res.data.data.code }),
          null,
          authHeader().Authorization,
        );
      })
      .catch((err) => console.log('error: ', err));
  } else if (item.warrantyStart === false && item.licenseStart != false) {
    console.warn('Nothing Missing!!!');
    return axios
      .all([insertWarranty(item), await insertLicense(item)])
      .then(
        axios.spread((warResponse, lcResponse) => {
          console.log('warResponse: ', warResponse);
          console.log('lcResponse: ', lcResponse);
          return postRequest(
            constants.API_INSERT,
            toApi({
              ...item,
              warranty: warResponse.data.data.code,
              license: lcResponse.data.data.code,
            }),
            null,
            authHeader().Authorization,
          );
        }),
      )
      .catch((err) => console.log('error: ', err));
  }

  // if (!isError)
  //   return await postRequest(
  //     constants.API_INSERT,
  //     toApi(item),
  //     null,
  //     authHeader().Authorization,
  //   );
  // else return { msg: 'error' };
}
async function insertItem_old(item) {
  if (item.warrantyStart != 'Invalid date') {
    var warranty = createWarranty(item);
    item = { ...item, warranty: warranty.warrantyCode };
    return await insertWarranty(warranty).then(
      async (res) => {
        return await postRequest(
          constants.API_INSERT,
          toApi(item),
          null,
          authHeader().Authorization,
        );
      },
      (err) => {
        console.error('err warranty: ', err);
        return err;
      },
    );
  } else {
    return await postRequest(
      constants.API_INSERT,
      toApi(item),
      null,
      authHeader().Authorization,
    );
  }
}

const updateItem = async (item) => {
  if (item.warrantyStart != 'Invalid date') {
    // var warranty = createWarranty(item);
    // console.log('created warranty: ', warranty);

    return await insertWarranty(item).then(
      async (res) => {
        console.log('insert warranty res: ', res);
        item = { ...item, warranty: res.warrantyCode };
        return await postRequest(
          constants.API_UPDATE,
          toApi(item),
          null,
          authHeader().Authorization,
        );
      },
      (err) => {
        console.error('err warranty: ', err);
        return err;
      },
    );
  } else if (item.warrantyStart == 'Invalid date') {
    return await insertWarranty(item).then(
      async (res) => {
        await postRequest(
          constants.API_UPDATE,
          toApi(item),
          null,
          authHeader().Authorization,
        );
      },
      (err) => {
        console.log('err warranty: ', err);
        return err;
      },
    );
  } else {
    return await postRequest(
      constants.API_UPDATE,
      toApi(item),
      null,
      authHeader().Authorization,
    );
  }
};

async function deleteItem(code) {
  return await postRequest(
    constants.API_DELETE,
    code,
    null,
    authHeader().Authorization,
  );
}
async function getItem(code) {
  return await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
}

async function getAssetItemBySerial(serial) {
  return await getRequest(
    constants.API_SEARCH_SERIAL + serial,
    null,
    authHeader().Authorization,
  );
}

async function getItemInterfaced(code) {
  var res = await getRequest(
    constants.API_GET + code,
    null,
    authHeader().Authorization,
  );
  let warranty;
  let obj = toModel(res.data.info);

  if (res.data.info.Warranty != '' && !!res.data.info.Warranty) {
    await getWarranty({ id: res.data.info.Code }).then((w) => {
      warranty = w.data.results[0];
    });
    obj.warrantyStart = warranty.StartDate;
    obj.warrantyEnd = warranty.EndDate;
    obj.period = warranty.Period;
    obj.periodUOM = warranty.WarrantyUnit;
  }

  let license;
  if (res.data.info.License != '' && !!res.data.info.License) {
    await getLicense({ id: res.data.info.Code }).then((l) => {
      console.log(l);
      license = l.data.results[0];
    });
    obj.licenseStart = license.StartDate;
    obj.licenseEnd = license.EndDate;
    obj.periodl = license.Period;
    obj.periodUOMl = license.LicenseUnit;
  }

  return getRefs(obj);
}

async function getAllItem() {
  return await getRequest(
    constants.API_GETALL,
    null,
    authHeader().Authorization,
  );
}

async function insertImage(position, code, file) {
  var bodyFormData = new FormData();
  var assetCode = code;

  bodyFormData.set('assetCode', code);
  bodyFormData.set('positions', position);
  bodyFormData.set('uploadedFiles', file);

  var positions = position;
  return await postRequest(
    constants.API_IMAGE_ADD,
    { assetCode, positions },
    bodyFormData,
    authHeader().Authorization,
    'multipart/form-data',
  );
}

async function updateImage(position, code, file) {
  var bodyFormData = new FormData();
  var assetCode = code;
  bodyFormData.append('assetCode', code);
  bodyFormData.append('positions', position);
  bodyFormData.append('uploadedFiles', file);

  const positions = [0];
  return await postRequest(
    constants.API_IMAGE_UPDATE,
    { assetCode, positions },
    bodyFormData,
    authHeader().Authorization,
    'multipart/form-data',
  );
}

async function updateImagePrimary(code, imageCode) {
  var assetCode = code;

  return await postRequest(
    constants.API_IMAGE_UPDATE_PRIMARY,
    { assetCode, imageCode },
    null,
    authHeader().Authorization,
    'multipart/form-data',
  );
}
async function deleteImage(position, code) {
  var bodyFormData = new FormData();
  bodyFormData.append('positions', [position]);
  return await postRequest(
    constants.API_IMAGE_DELETE + code,
    null,
    bodyFormData,
    authHeader().Authorization,
  );
}

async function getImage({ assetCode }) {
  return await getRequest(
    constants.API_IMAGE_GET + `${assetCode}`,
    null,
    authHeader().Authorization,
  ).catch((err) => err);
}

function insertWarrantySync(warranty) {
  return postRequestSync(
    constants.API_WARRANTY_INSERT,
    { ...warranty },
    null,
    authHeader().Authorization,
  );
}

function insertLicenseSync(license) {
  return postRequestSync(
    constants.API_LICENSE_INSERT,
    { ...license },
    null,
    authHeader().Authorization,
  );
}
const insertWarranty = async (item) => {
  let warranty = createWarranty(item);
  console.log('created warranty: ', warranty);
  return await postRequest(
    constants.API_WARRANTY_INSERT,
    warranty,
    null,
    authHeader().Authorization,
  );
};
async function insertLicense(item) {
  let license = createLicense(item);
  return await postRequest(
    constants.API_LICENSE_INSERT,
    { ...license },
    null,
    authHeader().Authorization,
  );
}
async function getWarranty({ id }) {
  var link = constants.API_WARRANTY_GET;
  return await getRequest(
    link.replace('id', id),
    null,
    authHeader().Authorization,
  );
}

async function getLicense({ id }) {
  var link = constants.API_LICENSE_GET;
  return await getRequest(
    link.replace('id', id),
    null,
    authHeader().Authorization,
  );
}
const toApiWithDescription = (model) => {};
const toApi = (model) => {
  return {
    code: model.code,
    barcode: model.barcode,
    referenceCode: model.refCode,
    part: model.pn,
    serial: model.serial,
    description: model.des,
    shortDescription: model.shortDes,
    parent: model.parent,
    color: model.color,
    assetUnit: model.unit,
    assetCondition: model.condition,
    costCenter: model.cost,
    assetBrand: model.brand,
    assetClass: model.clss,
    assetSubClass: model.subClass,
    assetGroup: model.group,
    assetModel: model.model,
    serialized: model.serialized,
    erp: model.erp,
    assetSupplier: model.supplier,
    warrantyStartDate: model.warrantyStart,
    warrantyEndDate: model.warrantyEnd,
    height: model.height,
    width: model.width,
    weight: model.weight,
    depth: model.depth,
    dimensionUomId: model.dimensionUOM,
    weightUomId: model.weightUOM,
    inventoryNumber: model.inventoryNo,
    active: model.active,
    warranty: model.warranty,
  };
};
const toModel = (api) => {
  return {
    code: api.Code,
    barcode: api.Barcode,
    refCode: api.ReferenceCode,
    pn: api.Part,
    des: api.Description,
    serial: api.Serial,
    shortDes: api.ShortDescription,
    parent: api.Parent,
    color: api.Color,
    unit: api.unit,
    condition: api.AssetCondition,
    cost: api.CostCenter,
    brand: api.AssetBrand,
    clss: api.AssetClass,
    subClass: api.AssetSubClass,
    group: api.AssetGroup,
    model: api.AssetModel,
    serialized: api.Serialized,
    erp: api.Erp,
    supplier: api.AssetSupplier,
    warrantyStart: api.WarrantyStartDate,
    warrantyEnd: api.WarrantyEndData,
    period: null,
    periodUOM: null,
    periodl: null,
    periodUOMl: null,
    licenseStart: api.LicenseStartDate,
    licenseEnd: api.LicenseEndDate,
    height: api.Height,
    width: api.Width,
    weight: api.Weight,
    depth: api.Depth,
    dimensionUOM: api.DimensionUomId,
    weightUOM: api.WeightUomId,
    inventoryNo: api.InventoryNumber,
    active: api.Active,
    ownerCode: api.Ownercode,
    ownerName: api.Ownername,
    ownerFirstName: api.Ownerfirstname,
    ownerLastName: api.Ownerlastname,
    ownerType: api.Ownertypename,
    ownerGroup: api.Ownergroupname,
  };
};

const createWarranty = (item) => {
  var moment_unit;
  if (item.warrantyStart === 'Invalid Date') {
    return {
      assetId: item.code,
      warrantyCode: uuid().toString(), // gen GUID
      startDate: moment(item.warrantyStart).format('YYYY-MM-DD'),
      endDate: endDate,
      warrantyBy: item.supplier,
      warrantyUnit: item.periodUOM,
      period: period,
    };
  }
  switch (item.periodUOM) {
    case 'DAY':
      moment_unit = 'days';
      break;
    case 'MONTH':
      moment_unit = 'months';
      break;
    case 'YEAR':
      moment_unit = 'years';
      break;
    default:
      moment_unit = 'days';
  }
  var endDate;
  var period;
  if (item.warrantyEnd === 'Invalid date' && !!item.period) {
    endDate = moment(item.warrantyStart)
      .add(item.period, moment_unit)
      .format('YYYY-MM-DD');
  } else if (item.warrantyEnd != 'Invalid date') {
    endDate = moment(item.warrantyEnd).format('YYYY-MM-DD');
    period = moment(item.warrantyStart).diff(
      moment(item.warrantyEnd),
      moment_unit,
    );
    if (period < 0) period = -1 * period;
  }
  var warranty = {
    assetId: item.code,
    warrantyCode: uuid(), // gen GUID
    startDate: moment(item.warrantyStart).format('YYYY-MM-DD'),
    endDate: endDate,
    warrantyBy: item.supplier,
    warrantyUnit: item.periodUOM,
    period: period,
  };
  return warranty;
};

function createLicense(item) {
  var moment_unit;

  switch (item.periodUOM) {
    case 'DAY':
      moment_unit = 'days';
      break;
    case 'MONTH':
      moment_unit = 'months';
      break;
    case 'YEAR':
      moment_unit = 'years';
      break;
    default:
      moment_unit = 'days';
  }
  var endDate;
  var period;
  if (item.licenseEnd === 'Invalid date' && !!item.periodl) {
    endDate = moment(item.licenseStart)
      .add(item.periodl, moment_unit)
      .format('YYYY-MM-DD');
  } else if (item.licenseEnd != 'Invalid date') {
    endDate = moment(item.licenseEnd).format('YYYY-MM-DD');
    period = moment(item.licenseStart).diff(
      moment(item.licenseEnd),
      moment_unit,
    );
    if (period < 0) period = -1 * period;
  }
  var license = {
    assetId: item.code,
    licenseCode: uuid(), // gen GUID
    startDate: moment(item.licenseStart).format('YYYY-MM-DD'),
    endDate: endDate,
    licenseBy: item.supplier,
    licenseUnit: item.periodUOMl,
    period: period,
  };
  return license;
}

export const assetService = {
  insertItem,
  updateItem,
  deleteItem,
  getItem,
  getAllItem,
  insertImage,
  updateImage,
  deleteImage,
  getImage,
  getItemInterfaced,
  insertWarranty,
  getWarranty,
  getAssetItemBySerial,
  updateImagePrimary,
};
