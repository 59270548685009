import React from 'react';

import LinkIcon from '@material-ui/icons/Link';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

export const CodeLinkedModalButton = (props) => {
  const { name, onClick, code } = props;
  const classes = useStyles();
  return (
    <>
      <Button
        variant="contained"
        size="large"
        className={classes.button}
        startIcon={<LinkIcon />}
        color="primary"
        onClick={onClick}
        name={name}
      >
        {code}
      </Button>
    </>
  );
};
