import { assetModelConstants as constants } from '../_constants';
import { assetModelService as services } from '../_services/assetModel.service';
import { alertActions as alerts } from './alert.action';
import { alertConstants as message } from '../_constants';

export const assetModelAction = {
  insert,
  update,
  remove,
  get,
  getAll,
};

function request(t) {
  return { type: t };
}
function success(t, res) {
  return { type: t, data: res };
}
function failure(t, error) {
  return { type: t, error };
}
function reset(t) {
  return { type: t };
}

function insert(data) {
  return (dispatch) => {
    dispatch(request(constants.INSERT_REQUEST));
    return services.insertItem(convert2Object(data)).then(
      (res) => {
        dispatch(success(constants.INSERT_SUCCESS, res));
        dispatch(alerts.success(message.INSERT_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.INSERT_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function update(data) {
  return (dispatch) => {
    dispatch(request(constants.UPDATE_REQUEST));
    return services.updateItem(convert2Object(data)).then(
      (res) => {
        dispatch(success(constants.UPDATE_SUCCESS, res));
        dispatch(alerts.success(message.UPDATE_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.UPDATE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function remove(data) {
  return (dispatch) => {
    dispatch(request(constants.DELETE_REQUEST));
    return services.deleteItem(data).then(
      (res) => {
        dispatch(success(constants.DELETE_SUCCESS, res));
        dispatch(alerts.success(message.DELETE_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.DELETE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function get(code) {
  return (dispatch) => {
    dispatch(request(constants.GET_REQUEST));
    return services.getItem(code).then(
      (res) => {
        console.log('res: ', res);
        let obj = convert2Select(res.data.info);
        var converted = {
          ...res,
          data: {
            ...res.data,
            info: JSON.parse(JSON.stringify(obj)),
          },
        };
        console.log('converted: ', converted);

        dispatch(success(constants.GET_SUCCESS, converted));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.GET_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function getAll() {
  return (dispatch) => {
    dispatch(request(constants.GETALL_REQUEST));
    return services.getAllItem().then(
      (res) => {
        dispatch(success(constants.GETALL_SUCCESS, res.data.results));
        return res;
      },
      (error) => {
        dispatch(failure(constants.GETALL_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

const convert2Object = (obj) => {
  let new_obj = {
    ...obj,
    assetGroup:
      !!obj.assetGroup && !!obj.assetGroup.value ? obj.assetGroup.value : '',
  };
  return new_obj;
};
const convert2Select = (obj) => {
  console.log('obj: ', obj);
  let new_obj = {
    ...obj,
    assetGroup: {
      value: obj.assetGroup,
      label: obj.assetGroupDescription,
    },
  };
  return new_obj;
};
