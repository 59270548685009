import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../_fragments';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Icon, Header } from 'semantic-ui-react';

import {
  Paper,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Card,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core';

export default function EmailConfig(props) {
  const classes = useStyles();
  // state
  const [state, setState] = React.useState({
    SMTPHost: '',
    SMTPPort: '',
    SMTPUsernmae: '',
    SMTPPassword: '',
    bcc: '',
  });
  // header
  const [title, setTitle] = React.useState({
    iconaction: 'add',
    content: 'Asset',
    description: 'Create',
  });
  // slide direction
  const [direction, setDirection] = React.useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });
  // submit
  const onSubmit = (e) => {
    console.log(state);
  };
  const handleChange = (e, data) => {
    const value = e.target.value;
    if (e.target.type === 'checkbox') {
      setState({ ...state, [e.target.name]: data });
    } else if (e.target.type === 'date') {
      setState({ ...state, [e.target.name]: value });
    } else {
      setState({
        ...state,
        [e.target.name]: value,
      });
    }
  };

  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <div>
          {/* {JSON.stringify(state)} */}

          <form noValidate autoComplete="off">
            {props.isView === true && 'This is view form'}
            <Grid item xs={12} md={12} lg={12} className={classes.container}>
              <Card variant="outlined" className={classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="mail" />
                    E-mail Notification Configuration
                  </Typography>
                </Header>
                <Grid
                  container
                  justify="left"
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  {/* SMTPHost */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPHost</FormLabel>
                      <TextField
                        name="SMTPHost"
                        size="small"
                        placeholder="SMTPHost"
                        value={state.SMTPHost}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  {/* SMTPPort */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPPort</FormLabel>
                      <TextField
                        name="SMTPPort"
                        size="small"
                        placeholder="SMTPPort"
                        value={state.SMTPPort}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  {/* SMTPPortUsername */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPPortUsername</FormLabel>
                      <TextField
                        name="SMTPPortUsername"
                        size="small"
                        placeholder="SMTPPortUsername"
                        value={state.SMTPPortUsername}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  {/* SMTP Password */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth required>
                      <FormLabel component="legend">SMTPPassword</FormLabel>
                      <TextField
                        name="SMTPPassword"
                        size="small"
                        placeholder="SMTPPassword"
                        value={state.SMTPPassword}
                        onChange={handleChange}
                        variant="outlined"
                        type="password"
                        required
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* bcc */}
                  <Grid item xs={12} md={12} xl={12}>
                    <FormControl margin="dense" fullWidth>
                      <FormLabel component="legend">bcc</FormLabel>
                      <TextField
                        name="bcc"
                        size="small"
                        placeholder="bcc"
                        value={state.bcc}
                        onChange={handleChange}
                        variant="outlined"
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <Button
                      variant="outlined"
                      size="large"
                      color="default"
                      onClick={onSubmit}
                      className={classes.btnSave}
                      startIcon={<SaveIcon />}
                      color={'primary'}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </form>
        </div>
      </Slide>
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  container: { padding: '0px !important' },
  group: { padding: theme.spacing(1), margin: theme.spacing(0) },
  card: { padding: theme.spacing(2), margin: theme.spacing(1) },
}));
