import React, { useState, useEffect } from 'react';

import { translate } from 'react-switch-lang';
import { Icon, Divider, Header } from 'semantic-ui-react';

import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReactSelect from 'react-select';
import Select from 'react-select';
import { Title } from '../../../_fragments';
import { useDispatch, useSelector } from 'react-redux';
import View from './View';
import { assetSchema as schema } from '../../../_validationSchema';
import { assetAction as actions } from '../../../_action/asset.action';
import { assetClassAction as classActions } from '../../../_action/assetClass.action';
import { assetSubClassAction as subClassActions } from '../../../_action/assetSubClass.action';
import { assetGroupAction as groupActions } from '../../../_action/assetGroup.action';
import { assetModelAction as modelActions } from '../../../_action/assetModel.action';
import { assetConditionAction as conditionActions } from '../../../_action/assetCondition.action';
import { costCenterAction as costActions } from '../../../_action/costCenter.action';
import { assetBrandAction as brandActions } from '../../../_action/assetBrand.action';
import { assetSupplierAction as supplierActions } from '../../../_action/assetSupplier.action';
import { uomAction as uomActions } from '../../../_action/uom.action';

import { Display } from '../../Display';
import { assetService as service } from '../../../_services';
import {
  InputSelectControlledRedux,
  InputDateControlled,
  InputSelectManualOption,
} from '../../../_fragments/formField/controllerFieldMUI';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Card,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core';
import ImageManager from '../AssetForm/ImageManager';

import { formatDate } from '../../../_helpers/time';
import { useMuiFormStyles } from '../../../theme/MuiFormStyles';

const defaultVals = {
  code: 'assetCode',
  barcode: '',
  refCode: '',
  pn: '',
  serial: '',
  des: '',
  shortDes: '',
  parent: '',
  color: '',
  unit: '',
  condition: '',
  cost: '',
  brand: '',
  clss: '',
  subClass: '',
  group: '',
  model: '',
  serialized: true,
  erp: false,
  supplier: '',
  // warrantyStart: '',
  // warrantyEnd: '',
  warrantyStart: '2021-01-01',
  warrantyEnd: '2021-01-01',
  period: '',
  periodUOM: '',
  licenseStart: '2021-01-01',
  licenseEnd: '2021-01-01',
  periodl: '',
  periodlUOM: '',
  height: '',
  width: '',
  depth: '',
  weight: '',
  dimensionUOM: '',
  weightUOM: '',
  inventoryNo: '',
  active: true,

  isNotify: true,
  isNotifyl: true,
};

const Form = (props) => {
  const classes = useMuiFormStyles();
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.assetOperation);
  const rootReducer = useSelector((state) => state);
  const [errors, setErrors] = useState({});
  const [showImageForm, setShowImage] = useState(true);
  const [formType, setFormType] = useState('');
  const [dateInputState, setDateInputState] = useState({
    disableEnd: true,
    disableP: true,
    disableEndl: true,
    disablePl: true,
  });
  const [title, setTitle] = useState({
    iconaction: 'add',
    content: 'Asset',
    description: 'Create',
  });
  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });

  const [state, setState] = useState(defaultVals);

  const onSubmit = async (e) => {
    e.preventDefault();
    let val = 0;
    setErrors({});
    console.log('submit state: ', state);

    // update state format
    var formatState = {
      ...state,
      unit: !!state.unit ? state.unit.code : '',
      condition: !!state.condition ? state.condition.code : '',
      cost: !!state.cost ? state.cost.code : '',
      brand: !!state.brand ? state.brand.code : '',
      clss: !!state.clss ? state.clss.code : '',
      subClass: !!state.subClass ? state.subClass.code : '',
      group: !!state.group ? state.group.code : '',
      model: !!state.model ? state.model.code : '',
      supplier: !!state.supplier ? state.supplier.code : '',
      dimensionUOM: !!state.dimensionUOM ? state.dimensionUOM.code : '',
      weightUOM: !!state.weightUOM ? state.weightUOM.code : '',
      periodUOM: !!state.periodUOM ? state.periodUOM.code : '',
      periodUOMl: !!state.periodUOMl ? state.periodUOMl.code : '',
      warrantyStart: !!state.warrantyStart && formatDate(state.warrantyStart),
      warrantyEnd: !!state.warrantyEnd && formatDate(state.warrantyEnd),
      licenseStart: !!state.licenseStart && formatDate(state.licenseStart),
      licenseEnd: !!state.licenseEnd && formatDate(state.licenseEnd),
    };

    try {
      console.log('formatState: ', formatState);
      console.log('form state: ', formType);

      val = await schema.validate(state, { abortEarly: false });
      console.log('val: ', val);
      if (formType.form === 'insert_form') {
        console.log('inserting: ');
        dispatch(actions.insert(formatState));
      } else if (formType.form === 'update_form') {
        console.log('updating: ');
        dispatch(actions.update(formatState));
      }
    } catch (err) {
      var tempErr = {};
      console.log('err: ', err);
      err.inner.map((info) => {
        tempErr = { ...tempErr, [info.params.path]: info.message };
      });
      setErrors(tempErr);
    }
  };

  const clearLicense = (e) => {
    e.preventDefault();
    setDateInputState({
      disableEndl: true,
      disablePl: true,
    });
    setState({
      ...state,
      licenseStart: '',
      licenseEnd: '',
      periodl: '',
      periodlUOM: '',
    });
  };
  const handleLicense = (e, data) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log('name: ', name);
    console.log('Data: ', data);

    if (name === 'licenseStart') {
      setState({ ...state, [name]: value });
      setDateInputState({
        ...dateInputState,
        disableEndl: false,
        disablePl: false,
      });
    } else if (name === 'licenseEnd') {
      setDateInputState({
        ...dateInputState,
        disableEndl: false,
        disablePl: true,
      });

      setState({ ...state, [name]: value, periodl: '' });
    } else if (name === 'periodl') {
      setDateInputState({
        ...dateInputState,
        disableEndl: true,
        disablePl: false,
      });
      if (value === '') {
        setDateInputState({
          ...dateInputState,
          disableEndl: false,
        });
      }
      setState({ ...state, [name]: value, licenseEnd: '' });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };
  const clearWarranty = (e) => {
    e.preventDefault();
    setDateInputState({
      ...dateInputState,
      disableEnd: true,
      disableP: true,
    });
    setState({
      ...state,
      warrantyStart: '',
      warrantyEnd: '',
      period: '',
      periodUOM: '',
    });
  };
  const handleWarranty = (e, data) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log('name: ', name);
    console.log('Data: ', data);

    if (name === 'warrantyStart') {
      console.log('date value: ', value);
      setState({ ...state, [name]: value });
      setDateInputState({
        ...dateInputState,
        disableEnd: false,
        disableP: false,
      });
    } else if (name === 'warrantyEnd') {
      setDateInputState({
        ...dateInputState,
        disableEnd: false,
        disableP: true,
      });

      setState({ ...state, [name]: value, period: '' });
    } else if (name === 'period') {
      setDateInputState({
        ...dateInputState,
        disableEnd: true,
        disableP: false,
      });
      if (value === '') {
        setDateInputState({
          ...dateInputState,
          disableEnd: false,
        });
      }
      setState({ ...state, [name]: value, warrantyEnd: '' });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };
  const handleChange = (e, data) => {
    const value = e.target.value;
    if (e.target.type === 'checkbox') {
      setState({ ...state, [e.target.name]: data });
    } else if (e.target.type === 'date') {
      setState({ ...state, [e.target.name]: value });
    } else {
      setState({
        ...state,
        [e.target.name]: value,
      });
    }
  };
  const handleSelect = (value, target) => {
    setState({ ...state, [target.name]: value });
  };
  const [options, setOptions] = useState({
    clssOp: [],
    subClassOp: [],
    groupOp: [],
    modelOp: [],
    uomOp: [],
  });

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      if (reducer.insertSuccess || reducer.updateSuccess) {
        props.history.push(formType.root);
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [reducer]);
  useEffect(() => {
    var isCancelled = false;
    if (rootReducer.assetClassOperation.getAllSuccess) {
      setOptions({
        ...options,
        clssOp: rootReducer.assetClassOperation.data,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [rootReducer.assetClassOperation]);
  useEffect(() => {
    var isCancelled = false;
    if (rootReducer.assetSubClassOperation.getAllSuccess) {
      setOptions({
        ...options,
        subClassOp: rootReducer.assetSubClassOperation.data,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [rootReducer.assetSubClassOperation]);
  useEffect(() => {
    var isCancelled = false;
    if (rootReducer.assetGroupOperation.getAllSuccess) {
      setOptions({
        ...options,
        groupOp: rootReducer.assetGroupOperation.data,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [rootReducer.assetGroupOperation]);
  useEffect(() => {
    var isCancelled = false;
    if (rootReducer.assetModelOperation.getAllSuccess) {
      setOptions({
        ...options,
        modelOp: rootReducer.assetModelOperation.data,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [rootReducer.assetModelOperation]);
  useEffect(() => {
    var isCancelled = false;
    if (rootReducer.uomOperation.getAllSuccess) {
      setOptions({
        ...options,
        uomOp: rootReducer.uomOperation.data,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [rootReducer.uomOperation]);
  useEffect(() => {
    var isCancelled = false;
    var state = props.location.state;
    if (!isCancelled) {
      setFormType(state);
      dispatch(classActions.getAll());
      dispatch(subClassActions.getAll());
      dispatch(groupActions.getAll());
      dispatch(modelActions.getAll());
      dispatch(uomActions.getAll());
      if (state.form === 'update_form' || state.form === 'view_form') {
        if (state.form === 'update_form') {
          setTitle({
            ...title,
            iconaction: 'edit',
            content: 'Asset',
            description: 'update',
          });
        } else if (state.form === 'view_form') {
          setTitle({
            ...title,
            iconaction: 'sitemap',
            content: 'Asset',
            description: 'view',
          });
        }

        service.getItemInterfaced(state.code).then((res) => {
          var licenseStart;
          if (res.licenseStart != '') {
            licenseStart = formatDate(res.licenseStart);
          }
          var licenseEnd;
          if (res.licenseEnd != '') {
            licenseEnd = formatDate(res.licenseEnd);
          }
          var warrantyStart;
          if (res.warrantyStart != '') {
            warrantyStart = formatDate(res.warrantyStart);
          }
          var warrantyEnd;
          if (res.warrantyEnd != '') {
            warrantyEnd = formatDate(res.warrantyEnd);
          }

          setState({
            ...res,
            warrantyStart,
            warrantyEnd,
            licenseStart,
            licenseEnd,
          });
        });

        setDateInputState({
          disableEnd: false,
          disableP: false,
          disableEndl: false,
          disablePl: false,
        });
        setShowImage(true);
      } else {
        setDateInputState({
          disableEnd: true,
          disableP: true,
          disableEndl: true,
          disablePl: true,
        });
        setShowImage(false);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  if (formType.form === 'view_form') {
    return (
      <>
        <View
          location={props.location}
          history={props.history}
          showImageForm={showImageForm}
          direction={direction}
          setDirection={setDirection}
          classes={classes}
          title={title}
          state={state}
        />
      </>
    );
  } else
    return (
      <>
        <Slide
          timeout={direction.timeout}
          direction={direction.slide}
          in={direction.in}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <form noValidate>
              {props.isView === true && 'This is view form'}
              {/* title */}
              <Grid container>
                <Grid item xs={12} md={6} lg={6}>
                  <Title
                    icon="cubes"
                    iconaction={title.iconaction}
                    content={title.content}
                    description={title.description}
                    props={props}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box justifyContent="flex-end" display="flex">
                    <Button
                      variant="outlined"
                      size="large"
                      color="default"
                      onClick={onSubmit}
                      className={classes.btnSave}
                      startIcon={<SaveIcon />}
                      color={'primary'}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      color="default"
                      onClick={(e) => {
                        e.preventDefault();
                        setDirection({
                          ...direction,
                          slide: 'left',
                          in: false,
                        });
                        setTimeout(() => {
                          props.history.push(props.location.state.root);
                        }, direction.timeout);
                      }}
                      className={classes.btnBack}
                      startIcon={<ArrowBackIcon />}
                    >
                      Back
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="column">
                {/* Basic Info */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.container}
                >
                  {/* Basic Info 1 */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="info circle" />
                        Basic Information Pt.1
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Code */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Code</FormLabel>
                          <TextField
                            name="code"
                            size="small"
                            placeholder="code"
                            value={state.code}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={
                              formType.form === 'update_form' ? true : false
                            }
                          />
                          <FormHelperText error={!!errors.code}>
                            {!!errors.code && errors.code}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Inventory Number */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            InventoryNumber
                          </FormLabel>
                          <TextField
                            name="inventoryNo"
                            size="small"
                            placeholder="InventoryNumber"
                            value={state.inventoryNo}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText error={!!errors.inventoryNo}>
                            {!!errors.inventoryNo && errors.inventoryNo}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Barcode */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Barcode</FormLabel>
                          <TextField
                            name="barcode"
                            size="small"
                            placeholder="barcode"
                            value={state.barcode}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText error={!!errors.barcode}>
                            {!!errors.barcode && errors.barcode}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Ref Code */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            ReferenceCode
                          </FormLabel>
                          <TextField
                            name="refCode"
                            size="small"
                            placeholder="refCode"
                            value={state.refCode}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* PN */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">P/N</FormLabel>
                          <TextField
                            name="pn"
                            size="small"
                            placeholder="P/N"
                            value={state.pn}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Serial */}
                      <Grid item xs={12} md={2} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Serial</FormLabel>
                          <TextField
                            name="serial"
                            size="small"
                            placeholder="Serial no."
                            value={state.serial}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Description */}
                      <Grid item xs={12} md={6} xl={6}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Description</FormLabel>
                          <TextField
                            name="des"
                            size="small"
                            placeholder="Description"
                            value={state.des}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Parent</FormLabel>
                          <TextField
                            name="parent"
                            size="small"
                            placeholder="Parent"
                            value={state.parent}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Color</FormLabel>
                          <TextField
                            name="color"
                            size="small"
                            placeholder="Color"
                            value={state.color}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* ShortDescription */}
                      <Grid item xs={12} md={4} xl={4}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            ShortDscription
                          </FormLabel>
                          <TextField
                            name="shortDes"
                            size="small"
                            placeholder="Short Description"
                            value={state.shortDes}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Basic Info 2 */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="info circle" />
                        Basic Information Pt.2
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Unit */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Unit</FormLabel>

                          <InputSelectManualOption
                            name="unit"
                            value={state.unit}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Condition */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Condition</FormLabel>
                          <InputSelectControlledRedux
                            name="condition"
                            value={state.condition}
                            onChange={handleSelect}
                            action={conditionActions}
                            reducer={rootReducer.assetConditionOperation}
                          />
                          {/* <InputSelectControlled
                            name="condition"
                            value={state.condition}
                            onChange={handleSelect}
                            service={condition.SERVICE}
                          /> */}
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Cose Center */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">CostCenter</FormLabel>
                          <InputSelectControlledRedux
                            name="cost"
                            value={state.cost || ''}
                            onChange={handleSelect}
                            action={costActions}
                            reducer={rootReducer.costCenterOperation}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}></Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.serialized}
                                onChange={handleChange}
                                name="serialized"
                                color="primary"
                              />
                            }
                            label="isSerialized"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.erp}
                                onChange={handleChange}
                                name="erp"
                                color="primary"
                              />
                            }
                            label="ERP"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.active}
                                onChange={handleChange}
                                name="active"
                                color="primary"
                              />
                            }
                            label="isActive"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Owner Info*/}

                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="user circle outline" />
                        Owner
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <Display
                            txt={
                              state.ownerCode +
                              ' - ' +
                              state.ownerName +
                              ' - ' +
                              state.ownerFirstName +
                              ' - ' +
                              state.ownerLastName +
                              ' - ' +
                              state.ownerType +
                              ' - ' +
                              state.ownerGroup
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>

                  {/* Classification */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="tag" />
                        Classification
                      </Typography>
                    </Header>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      {/* Supplier */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Supplier</FormLabel>

                          <InputSelectControlledRedux
                            name="supplier"
                            value={state.supplier}
                            onChange={handleSelect}
                            action={supplierActions}
                            reducer={rootReducer.assetSupplierOperation}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Brand */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Brand</FormLabel>
                          <InputSelectControlledRedux
                            name="brand"
                            value={state.brand}
                            onChange={handleSelect}
                            action={brandActions}
                            reducer={rootReducer.assetBrandOperation}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} xl={6}></Grid>
                      {/* Class */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Class</FormLabel>
                          <Select
                            name={'clss'}
                            onChange={(value, target) => {
                              if (target.action === 'clear') {
                                setState({
                                  ...state,
                                  clss: '',
                                  subClass: '',
                                  group: '',
                                  model: '',
                                });
                              } else setState({ ...state, clss: value });
                            }}
                            isClearable
                            value={state.clss}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            options={options.clssOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* SubClass */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">SubClass</FormLabel>
                          <ReactSelect
                            key={state.clss}
                            name="subClass"
                            onChange={(value) => {
                              setState({ ...state, subClass: value });
                            }}
                            isClearable
                            value={state.subClass}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            filterOption={(value) => {
                              if (state.clss === '')
                                return value.data.assetClass === state.clss;
                              return value.data.assetClass === state.clss.code;
                            }}
                            options={options.subClassOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Group */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Group</FormLabel>
                          <ReactSelect
                            key={state.subClass}
                            name="group"
                            onChange={(value) => {
                              setState({ ...state, group: value });
                            }}
                            isClearable
                            value={state.group}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            filterOption={(value) => {
                              if (state.subClass === '')
                                return (
                                  value.data.assetSubClass === state.subClass
                                );
                              return (
                                value.data.assetSubClass === state.subClass.code
                              );
                            }}
                            options={options.groupOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* Model */}
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">Model</FormLabel>
                          <ReactSelect
                            key={state.model}
                            name="model"
                            onChange={(value) => {
                              setState({ ...state, model: value });
                            }}
                            isClearable
                            value={state.model}
                            getOptionLabel={(op) =>
                              op.code + ' - ' + op.description
                            }
                            getOptionValue={(op) => op.code}
                            filterOption={(value) => {
                              if (state.group === '')
                                return value.data.assetGroup === state.group;
                              return value.data.assetGroup === state.group.code;
                            }}
                            options={options.modelOp}
                            menuPortalTarget={document.body}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Warranty_Liscense */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="calendar check outline" />
                        Warranty
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl>
                          <Button
                            variant="outlined"
                            size="small"
                            color="default"
                            onClick={clearWarranty}
                            className={classes.btnSave}
                            // startIcon={<SaveIcon />}
                            color={'primary'}
                          >
                            Clear
                          </Button>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.isNotify}
                                onChange={handleChange}
                                name="isNotify"
                                color="primary"
                              />
                            }
                            label="notification"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            warrantyStartDate
                          </FormLabel>
                          <InputDateControlled
                            name="warrantyStart"
                            value={state.warrantyStart}
                            onChange={handleWarranty}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            warrantyEndtDate
                          </FormLabel>
                          <InputDateControlled
                            name="warrantyEnd"
                            value={state.warrantyEnd}
                            onChange={handleWarranty}
                            disabled={dateInputState.disableEnd}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Period</FormLabel>
                          <TextField
                            name="period"
                            size="small"
                            type="number"
                            placeholder="period"
                            value={state.period || ''}
                            onChange={handleWarranty}
                            disabled={dateInputState.disableP}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">PeriodUnit</FormLabel>

                          <InputSelectManualOption
                            name="periodUOM"
                            value={state.periodUOM}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* License */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="calendar check outline" />
                        License
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={1} xl={1}>
                        <FormControl>
                          <Button
                            variant="outlined"
                            size="small"
                            color="default"
                            onClick={clearLicense}
                            className={classes.btnSave}
                            // startIcon={<SaveIcon />}
                            color={'primary'}
                          >
                            Clear
                          </Button>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.isNotifyl}
                                onChange={handleChange}
                                name="isNotifyl"
                                color="primary"
                              />
                            }
                            label="notification"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            licenseStartDate
                          </FormLabel>
                          <InputDateControlled
                            name="licenseStart"
                            value={state.licenseStart}
                            onChange={handleLicense}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            licenseEndDate
                          </FormLabel>
                          <InputDateControlled
                            name="licenseEnd"
                            value={state.licenseEnd}
                            onChange={handleLicense}
                            disabled={dateInputState.disableEndl}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Period</FormLabel>
                          <TextField
                            name="periodl"
                            size="small"
                            type="number"
                            placeholder="period"
                            value={state.periodl || ''}
                            onChange={handleWarranty}
                            disabled={dateInputState.disablePl}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} xl={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">PeriodUnit</FormLabel>

                          <InputSelectManualOption
                            name="periodUOMl"
                            value={state.periodUOMl}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                  {/* Dimension */}
                  <Card variant="outlined" className={classes.card}>
                    <Header dividing>
                      <Typography variant="h6" color="textPrimary">
                        <Icon name="resize horizontal" />
                        Dimension
                      </Typography>
                    </Header>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      spacing={3}
                    >
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Height</FormLabel>
                          <TextField
                            name="height"
                            size="small"
                            type="number"
                            placeholder="Height"
                            value={state.height}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Width</FormLabel>
                          <TextField
                            name="width"
                            size="small"
                            type="number"
                            placeholder="Width"
                            value={state.width}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Weight</FormLabel>
                          <TextField
                            name="weight"
                            size="small"
                            type="number"
                            placeholder="weight"
                            value={state.weight}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl fullWidth margin="dense">
                          <FormLabel component="legend">Depth</FormLabel>
                          <TextField
                            name="depth"
                            size="small"
                            type="number"
                            placeholder="Depth"
                            value={state.depth}
                            onChange={handleChange}
                            variant="outlined"
                          />
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">
                            DimensionUnit
                          </FormLabel>

                          <InputSelectManualOption
                            name="dimensionUOM"
                            value={state.dimensionUOM}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} xl={2}>
                        <FormControl margin="dense" fullWidth>
                          <FormLabel component="legend">WeightUnit</FormLabel>

                          <InputSelectManualOption
                            name="weightUOM"
                            value={state.weightUOM}
                            onChange={handleSelect}
                            options={options.uomOp}
                          />

                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                {showImageForm && (
                  <>
                    <Divider />

                    <Grid item xs={12} md={12} lg={12}>
                      <Card className={classes.group}>
                        <ImageManager code={props.location.state.code} />
                      </Card>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </div>
        </Slide>
      </>
    );
};

export default translate(Form);
