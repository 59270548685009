export const departmentConstants = {
  API_INSERT: '/api/department/insert',
  API_UPDATE: '/api/department/update',
  API_DELETE: '/api/department/delete',
  API_GET: '/api/department/',
  API_GETALL: '/api/department/getall',

  INSERT_REQUEST: 'DEPARTMENT_INSERT_REQUEST',
  INSERT_SUCCESS: 'DEPARTMENT_INSERT_SUCCESS',
  INSERT_FAILURE: 'DEPARTMENT_INSERT_FAILURE',

  UPDATE_REQUEST: 'DEPARTMENT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DEPARTMENT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DEPARTMENT_UPDATE_FAILURE',

  DELETE_REQUEST: 'DEPARTMENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'DEPARTMENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'DEPARTMENT_DELETE_FAILURE',

  GET_REQUEST: 'DEPARTMENT_GET_REQUEST',
  GET_SUCCESS: 'DEPARTMENT_GET_SUCCESS',
  GET_FAILURE: 'DEPARTMENT_GET_FAILURE',

  GETALL_REQUEST: 'DEPARTMENT_GETALL_REQUEST',
  GETALL_SUCCESS: 'DEPARTMENT_GETALL_SUCCESS',
  GETALL_FAILURE: 'DEPARTMENT_GETALL_FAILURE',

  RESET: 'DEPARTMENT_RESET',
  SERVICE: 'DEPARTMENT_SERVICE',
};
