import * as yup from 'yup'; // for everything
export const schema = yup.object().shape({
  code: yup.string().min(2, 'code is too short').required('code is required'),
  // barcode: yup.string().min(2, 'barcode is too short'),
  // code: yup.string().min(2, 'too short').required(),
  // assetUnit: yup.object().shape({
  //   value: yup.string().required(),
  //   label: yup.string().required(),
  // })
  // period: yup.number().min(1).nullable(),
  // warrantyEnd: yup.date().when('warrantyStart', (warrantyStart, schema) => {
  //   return warrantyStart && yup.date().min(warrantyStart);
  // }),
});
