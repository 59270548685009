import React from 'react';

import { translate } from 'react-switch-lang';
import { Icon, Divider, Header } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core/styles';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Title } from '../../../_fragments';
import { Display } from '../../Display';
import { Label } from '../../Label';

import {
  Paper,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Card,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core';
import ImageManager from '../AssetForm/ImageManager';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },

  button: {
    margin: theme.spacing(1),
  },
  container: { padding: '0px !important' },
  group: { padding: theme.spacing(1), margin: theme.spacing(0) },
  card: { padding: theme.spacing(2), margin: theme.spacing(1) },
}));
// const Label = ({ txt }) => (
//   <Typography variant="h6" component="h6">
//     {txt}
//   </Typography>
// );
// const Display = ({ txt }) => (
//   <Typography variant="body" component="body" color="primary">
//     {txt === '' || txt === null ? '-' : txt}
//   </Typography>
// );
const View = (props) => {
  const { state } = props;

  return (
    <>
      <Slide
        timeout={props.direction.timeout}
        direction={props.direction.slide}
        in={props.direction.in}
        mountOnEnter
        unmountOnExit
      >
        {/* {JSON.stringify(state)} */}

        <form>
          {props.isView === true && 'This is view form'}
          <Grid container justify="center" className={props.classes.container}>
            <Grid item xs={12} md={6} lg={6}>
              <Title
                icon="cubes"
                iconaction={props.title.iconaction}
                content={props.title.content}
                description={props.title.description}
                props={props}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box justifyContent="flex-end" display="flex">
                <Button
                  variant="contained"
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setDirection({
                      ...props.direction,
                      slide: 'left',
                      in: false,
                    });
                    setTimeout(() => {
                      props.history.push(props.location.state.root);
                    }, props.direction.timeout);
                  }}
                  className={props.classes.button}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              className={props.classes.container}
            >
              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="info circle" />
                    Basic Information Pt.1
                  </Typography>
                </Header>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Code" />
                  </FormLabel>
                  <Display txt={state.code} />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="InventoryNumber" />
                  </FormLabel>
                  <Display txt={state.inventoryNo} />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Barcode" />
                  </FormLabel>

                  <Display txt={state.barcode} />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="ReferenceCode" />
                  </FormLabel>

                  <Display txt={state.refCode} />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="P/N" />
                  </FormLabel>

                  <Display txt={state.pn} />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Serial" />
                  </FormLabel>

                  <Display txt={state.serial} />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Description" />
                  </FormLabel>

                  <Display txt={state.des} />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="ShortDscription" />
                  </FormLabel>

                  <Display txt={state.shortDes} />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Parent" />
                  </FormLabel>

                  <Display txt={state.parent} />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Color" />
                  </FormLabel>

                  <Display txt={state.color} />
                </FormControl>
              </Card>
              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="user circle outline" />
                    Owner
                  </Typography>
                </Header>

                <FormControl margin="dense" fullWidth>
                  <Display
                    txt={
                      state.ownerCode +
                      ' - ' +
                      state.ownerName +
                      ' - ' +
                      state.ownerFirstName +
                      ' - ' +
                      state.ownerLastName +
                      ' - ' +
                      state.ownerType +
                      ' - ' +
                      state.ownerGroup
                    }
                  />{' '}
                </FormControl>
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4} className={props.classes.group}>
              
            </Grid> */}
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              className={props.classes.container}
            >
              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="info" />
                    Basic Information Pt.2
                  </Typography>
                </Header>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Unit" />
                  </FormLabel>
                  <Display
                    txt={
                      !!state.unit
                        ? state.unit.code + ' - ' + state.unit.description
                        : ''
                    }
                  />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Condition" />
                  </FormLabel>
                  <Display
                    txt={
                      !!state.condition
                        ? state.condition.code +
                          ' - ' +
                          state.condition.description
                        : ''
                    }
                  />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.serialized ? true : false}
                        name="serialized"
                        color="primary"
                      />
                    }
                    label="isSerialized"
                  />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.erp ? true : false}
                        name="erp"
                        color="primary"
                      />
                    }
                    label="ERP"
                  />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.active ? true : false}
                        name="active"
                        color="primary"
                      />
                    }
                    label="isActive"
                  />
                </FormControl>
              </Card>

              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="warehouse" />
                    CostCenter
                  </Typography>
                </Header>
                <FormControl margin="dense" fullWidth>
                  <Display
                    txt={
                      !!state.cost
                        ? state.cost.code + ' - ' + state.cost.description
                        : ''
                    }
                  />
                </FormControl>
              </Card>

              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="tag" />
                    Classification
                  </Typography>
                </Header>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Brand" />
                  </FormLabel>
                  <Display
                    txt={
                      !!state.brand
                        ? state.brand.code + ' - ' + state.brand.description
                        : ''
                    }
                  />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Class" />
                  </FormLabel>
                  <Display
                    txt={
                      !!state.clss
                        ? state.clss.code + ' - ' + state.clss.description
                        : ''
                    }
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="SubClass" />
                  </FormLabel>
                  <Display
                    txt={
                      !!state.subClass
                        ? state.subClass.code +
                          ' - ' +
                          state.subClass.description
                        : ''
                    }
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Group" />
                  </FormLabel>
                  <Display
                    txt={
                      !!state.group
                        ? state.group.code + ' - ' + state.group.description
                        : ''
                    }
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="Model" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.model
                        ? state.model.code + ' - ' + state.model.description
                        : ''
                    }
                  />
                </FormControl>
              </Card>
              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="bookmark" />
                    Supplier
                  </Typography>
                </Header>
                <FormControl margin="dense" fullWidth>
                  <Display
                    txt={
                      !!state.supplier
                        ? state.supplier.code +
                          ' - ' +
                          state.supplier.description
                        : ''
                    }
                  />
                </FormControl>
              </Card>

              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="calendar check outline" />
                    Warranty
                  </Typography>
                </Header>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="warrantyStartDate" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.warrantyStart
                        ? moment(state.warrantyStart)
                            .format('DD-MM-YYYY')
                            .toString()
                        : ''
                    }
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="warrantyEndDate" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.warrantyEnd
                        ? moment(state.warrantyEnd)
                            .format('DD-MM-YYYY')
                            .toString()
                        : ''
                    }
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <FormLabel component="legend">
                    <Label txt="Period" />
                  </FormLabel>

                  <Display txt={state.period} />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="PeriodUnit" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.periodUOM
                        ? state.periodUOM.code +
                          ' - ' +
                          state.periodUOM.description
                        : ''
                    }
                  />
                </FormControl>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={4}
              className={props.classes.container}
            >
              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="calendar check outline" />
                    License
                  </Typography>
                </Header>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="licenseStartDate" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.licenseStart
                        ? moment(state.licenseStart)
                            .format('DD-MM-YYYY')
                            .toString()
                        : ''
                    }
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="licenseEndDate" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.licenseEnd
                        ? moment(state.licenseEnd)
                            .format('DD-MM-YYYY')
                            .toString()
                        : ''
                    }
                  />
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <FormLabel component="legend">
                    <Label txt="Period" />
                  </FormLabel>

                  <Display txt={state.period} />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="PeriodUnit" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.periodUOM
                        ? state.periodUOM.code +
                          ' - ' +
                          state.periodUOM.description
                        : ''
                    }
                  />
                </FormControl>
              </Card>
              <Card variant="outlined" className={props.classes.card}>
                <Header dividing>
                  <Typography variant="h6" color="textPrimary">
                    <Icon name="resize horizontal" />
                    Dimension
                  </Typography>
                </Header>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                  spacing={3}
                >
                  <Grid item xs={12} md={6} xl={6}>
                    <FormControl fullWidth margin="dense">
                      <FormLabel component="legend">
                        <Label txt="Height" />
                      </FormLabel>

                      <Display txt={state.height} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <FormControl fullWidth margin="dense">
                      <FormLabel component="legend">
                        <Label txt="Width" />
                      </FormLabel>

                      <Display txt={state.width} />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                  spacing={3}
                >
                  <Grid item xs={12} md={6} xl={6}>
                    <FormControl fullWidth margin="dense">
                      <FormLabel component="legend">
                        <Label txt="Weight" />
                      </FormLabel>

                      <Display txt={state.weight} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <FormControl fullWidth margin="dense">
                      <FormLabel component="legend">
                        <Label txt="Depth" />
                      </FormLabel>

                      <Display txt={state.depth} />
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="DimensionUnit" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.dimensionUOM
                        ? state.dimensionUOM.code +
                          ' - ' +
                          state.dimensionUOM.description
                        : ''
                    }
                  />
                </FormControl>

                <FormControl margin="dense" fullWidth>
                  <FormLabel component="legend">
                    <Label txt="WeightUnit" />
                  </FormLabel>

                  <Display
                    txt={
                      !!state.weightUOM
                        ? state.weightUOM.code +
                          ' - ' +
                          state.weightUOM.description
                        : ''
                    }
                  />
                </FormControl>
              </Card>
              {props.showImageForm && (
                <Grid item xs={12} md={12} lg={12}>
                  <Card className={props.classes.group}>
                    <ImageManager
                      readOnly={true}
                      code={props.location.state.code}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Slide>
    </>
  );
};

export default translate(View);
