import React from 'react';
import { translate } from 'react-switch-lang';
import MaterialTable from 'material-table';
import { jQGridService, roleService } from '../../../_services';
import { tableIcons } from './tableIcons';

import palettle from '../../../theme/palette';
import { ViewModal } from '../../_modal';
import { CodeLinkedModalButton } from '../../button';
import { formHeader } from '../../../_helpers';
import { formType } from '../../../_constants';
import { useMuiFormStyles } from '../../../theme/MuiFormStyles';

const rowTransform = (rows, onClick) => {
  console.log('rows:', rows);
  return rows.map((r) => {
    console.log('a row: ', r);
    return {
      ...r,
      DOCUMENTCODE: (
        <CodeLinkedModalButton
          name={r.DOCUMENTCODE}
          onClick={() => onClick(r.DOCUMENTCODE)}
          code={r.DOCUMENTCODE}
        >
          {r.DOCUMENTCODE}
        </CodeLinkedModalButton>
      ),
      OWNER:
        r.OWNERCODE +
        ' - ' +
        r.OWNERFIRSTNAME +
        ' - ' +
        r.OWNERLASTNAME +
        ' - ' +
        r.OWNERTYPE +
        ' - ' +
        r.OWNERGROUP,
    };
  });
};
const HistoryDatatable = (props) => {
  const { t, title, columns, menuCode, code } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const [actions, setActions] = React.useState([]);
  const role = user.roleinfo; // {code, description}
  const [modalState, setModalState] = React.useState({
    open: false,
    header: formHeader(t, 'View document', 'cube', formType.VIEW),
    size: 'small',
    selectedCode: '',
  });
  React.useEffect(() => {
    let isCancelled = false;
    roleService.getRolePermissions(role.code).then((res) => {
      if (!isCancelled) {
        var menuPermission = res.data.results.find(
          (o) => o.menuCode === menuCode,
        );
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <>
      <ViewModal
        open={modalState.open}
        onClose={() => setModalState({ ...modalState, open: false })}
        header={modalState.header}
        size={modalState.size}
        classes={useMuiFormStyles()}
      >
        {modalState.selectedCode}
      </ViewModal>
      <MaterialTable
        icons={tableIcons}
        // actions={actions}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters = query.filters;
            filters = filters.map((el) => {
              return {
                title: el.column.title,
                field: el.column.field,
                operator: el.operator,
                value: el.value,
              };
            });
            try {
              jQGridService
                .search(
                  props.filenameXML + '.xml',
                  query.page + 1,
                  query.pageSize,
                  code,
                )
                .then((res) => {
                  if (!!res.data) {
                    console.log('res.data: ', res.data);
                    //rowTransform(res.data.rows);
                    resolve({
                      data: rowTransform(res.data.rows, (code) =>
                        setModalState({
                          ...modalState,
                          open: true,
                          selectedCode: code,
                        }),
                      ),
                      page: res.data.page - 1,
                      totalCount: res.data.records,
                    });
                  } else {
                    resolve({
                      data: [],
                      page: query.page,
                      totalCount: query.totalCount,
                    });
                  }
                });
            } catch (error) {
              console.log('error: ', error);
              resolve({
                data: [],
                page: query.page,
                totalCount: query.totalCount,
              });
            }
          })
        }
        options={{
          // filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: palettle.background.default,
            color: palettle.primary.main,
          },

          pageSize: 10,
          pageSizeOptions: [5, 10, 100],
        }}
        title={title}
      />
    </>
  );
};

export default translate(HistoryDatatable);
