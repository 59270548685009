import React from 'react';

import { Card, Button, Grid, Checkbox } from 'semantic-ui-react';
import DeleteIcon from '@material-ui/icons/Delete';

export const ImageCard = ({
  imageProp,

  deleteImage,
  readOnly,
  setPrimary,
}) => {
  const [checked, setChecked] = React.useState(false);
  const onDelete = (e) => {
    e.preventDefault();
    deleteImage(imageProp.position);
  };
  React.useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      setChecked(imageProp.primaryImage);
    }
    return () => {
      isCancelled = true;
    };
  });
  return (
    <>
      <Card raised>
        <img src={imageProp.resizeImagePath} height={200} />
        <Card.Content>
          <Checkbox
            label="Primary image"
            disabled={readOnly}
            checked={checked}
            onChange={(e, obj) => {
              setChecked(true);
              console.log('e: ', obj);
              if (obj.checked) setPrimary(imageProp.code);
            }}
          />
          <Card.Description>
            {'position: ' + imageProp.position}
          </Card.Description>
        </Card.Content>

        <Card.Content extra>
          <Grid>
            <Grid.Column floated="left" width={10}>
              Date: {imageProp.createDate}
            </Grid.Column>
            <Grid.Column floated="right" width={5}>
              <Button
                icon={<DeleteIcon />}
                onClick={onDelete}
                disabled={readOnly}
                secondary
              />
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    </>
  );
};
