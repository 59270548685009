import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { OwnerChangeServices as service } from './services/asset.ownerchange.service';
import {
  getCurrentOwner,
  getCurrentOwnerType,
} from '../assetcheck/redux/actions/assetcheck.action';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

function AssetOwnerChange_Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, register, setValue } = props;

  //selector
  const assetTransferStore = useSelector((state) => state.assetTable);

  const init = {
    code: '',
    date: moment().format('YYYY-MM-DD'),
    changeBy: '',
    sourceOwner: '',
    sourceOwnerType: '',
    destOwnerType: '',
    reason: '',
    remark: '',
  };
  const [values, setValues] = useState(init);

  const initialOwnerOption = [
    {
      value: 1,
      label: 'Company',
    },
    {
      value: 2,
      label: 'Branch',
    },
    {
      value: 3,
      label: 'Department',
    },
    {
      value: 4,
      label: 'Customer',
    },
    {
      value: 5,
      label: 'Employee',
    },
  ];
  const [ownerTypeOption, setownerTypeOption] = useState(initialOwnerOption);
  const [onwersOption, setOnwersOption] = useState([]);
  const [onwerDescsOption, setOnwersDescOption] = useState([]);
  const [optionReason, setoptionReason] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  //*** Initial get values ****/
  useEffect(() => {
    // console.log(props.readOnly);
    service.getReason().then((res) => {
      res.data.results
        .filter((option) => option.reasonType == 'OWNERCHANGE')
        .map((item) => {
          setoptionReason((option) => [
            ...option,
            {
              value: item.value,
              label: item.label,
            },
          ]);
        });
    });
  }, []);

  //*** UPDATE GET VALUE */
  useEffect(() => {
    //check edit action
    if (!!props.code) {
      service
        .getItem(props.code)
        .then((res) => {
          setValues(res.data.result);

          if (!!res.data.result.reason)
            props.setValue('reason', {
              value: res.data.result.reason,
              label: res.data.result.reason,
            });

          props.setValue('sourceOwnerType', {
            value: res.data.result.sourceOwnerType,
            //label: res.data.result.sourceOwnerType,
            label: initialOwnerOption.find(
              (i) => i.value == res.data.result.sourceOwnerType,
            ).label,
          });

          props.setValue('sourceOwner', {
            value: res.data.result.sourceOwner,
            label: res.data.result.sourceOwner,
          });

          props.setValue('destOwnerType', {
            value: res.data.result.destOwnerType,
            label: initialOwnerOption.find(
              (i) => i.value == res.data.result.destOwnerType,
            ).label,
          });

          props.setValue('changeBy', {
            value: res.data.result.changeBy,
            label: res.data.result.changeBy,
          });

          //console.log(res.data.result);
        })
        .catch((error) => {
          window.alert('error', JSON.stringify(error));
        });
    }
  }, []);

  useEffect(() => {
    ownersQuery(values.sourceOwnerType.value, '').then((opts) => {
      setOnwersOption(opts);
    });
  }, [values['sourceOwnerType']]);

  useEffect(() => {
    ownersQuery(values.destOwnerType.value, '').then((opts) => {
      setOnwersDescOption(opts);
    });
  }, [values['destOwnerType']]);

  useEffect(() => {
    if (assetTransferStore.is_readyonly) setIsReadOnly(true);
  }, [assetTransferStore]);

  useEffect(() => {
    dispatch(
      getCurrentOwner({
        owner: values.sourceOwner,
        ownertype: values.sourceOwnerType,
      }),
    );
  }, [values['sourceOwner']]);

  //Owneroption loading
  const ownersQuery = async (ownerType, inputValue) => {
    try {
      const res = await service.autoComplete({
        req_type: ownerType,
        req_search: inputValue,
        recordTotal: '30',
      });

      const resData = Object.keys(res).map(
        (key) =>
          new Object({
            value: res[key].value,
            label: res[key].label,
          }),
      );

      return resData;
    } catch (error) {
      console.log('error:', error);
    }
  };

  const handleChange = (event) => {
    //console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    if (event[0] != null) {
      setValues({
        ...values,
        [event[1].name]: { value: event[0].value, label: event[0].label },
      });
    } else {
      setValues({
        ...values,
        [event[1].name]: '',
      });
    }
  };

  return (
    <>
      <Grid justify="center" container direction="row" spacing={1}>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.input} fullWidth margin="dense">
            <FormLabel component="legend">{t('Code')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              name="code"
              value={values.code}
              inputRef={register()}
              onChange={handleChange}
              disabled
            />
            <FormHelperText>
              {props.errors.code?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            required
            error={!!props.errors.dateValue}
          >
            <FormLabel component="legend">{t('Date')}</FormLabel>
            <TextField
              size="small"
              type="date"
              variant="outlined"
              name="date"
              value={[moment(values.date).format('YYYY-MM-DD')]}
              inputRef={props.register({ required: true })}
              onChange={handleChange}
              disabled={props.readOnly}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormHelperText>
              {props.errors.date?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
            error={!!props.errors.Reason}
          >
            <FormLabel component="legend">{t('Reason')}</FormLabel>
            <Controller
              name="reason"
              as={<Select />}
              options={optionReason}
              defaultValue={values.reason}
              isClearable
              control={props.control}
              rules={{ required: 'Reason is Required' }}
              isDisabled={props.readOnly}
            />
            <FormHelperText error>
              {!!props.errors.reason && <>{props.errors.reason.message}</>}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            error={!!props.errors.remark}
          >
            <FormLabel component="legend">{t('Remark')}</FormLabel>
            <TextField
              size="small"
              variant="outlined"
              name="remark"
              value={values.remark}
              inputRef={register}
              onChange={handleChange}
              disabled={props.readOnly}
            />
            <FormHelperText>
              {props.errors.remark?.type === 'required' &&
                'Your input is required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
            // error={!!props.errors.sourceOwnerType}
          >
            <FormLabel component="legend">{t('sourceOwnerType')}</FormLabel>

            <Controller
              name="sourceOwnerType"
              as={<Select />}
              options={ownerTypeOption}
              defaultValue={values.ownerTypeOption}
              isClearable
              control={props.control}
              onChange={(value) => {
                setValue('sourceOwner', '');
                setValue('borrowby', '');
                handleSelectChange(value);
                return value[0];
              }}
              rules={{ required: 'sourceOwnerType is Required' }}
              isDisabled={isReadOnly}
            />
            <FormHelperText error>
              {!!props.errors.sourceOwnerType && (
                <>{props.errors.sourceOwnerType.message}</>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
            // error={!!props.errors.sourceOwner}
          >
            <FormLabel component="legend">{t('sourceOwner')}</FormLabel>
            <Controller
              as={<AsyncSelect />}
              options={onwersOption ? onwersOption : []}
              name="sourceOwner"
              isClearable
              isDisabled={isReadOnly}
              control={props.control}
              rules={{ required: 'Required' }}
              defaultOptions={onwersOption}
              loadOptions={(value) => {
                if (!values.sourceOwnerType) {
                  window.alert('please select source ownertype first');
                  return null;
                }
                return ownersQuery(values.sourceOwnerType.value, value);
              }}
              placeholder={
                values.sourceOwnerType.label
                  ? 'Search by ' + values.sourceOwnerType.label
                  : 'Select...'
              }
              onChange={(value) => {
                // console.log(value);
                handleSelectChange(value);
                return value[0];
              }}
            />
            <FormHelperText error>
              {!!props.errors.sourceOwner && (
                <>{props.errors.sourceOwner.message}</>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
          >
            <FormLabel component="legend">{t('destOwnerType')}</FormLabel>
            <Controller
              name="destOwnerType"
              as={<Select />}
              options={ownerTypeOption}
              defaultValue={values.destOwnerType}
              isClearable
              control={props.control}
              onChange={(value) => {
                setValue('changeBy', '');
                handleSelectChange(value);
                return value[0];
              }}
              rules={{ required: 'destOwnerType is Required' }}
              isDisabled={isReadOnly}
            />
            <FormHelperText error>
              {!!props.errors.destOwnerType && (
                <>{props.errors.destOwnerType.message}</>
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl
            className={classes.input}
            fullWidth
            margin="dense"
            size="small"
            required={true}
          >
            <FormLabel component="legend">{t('changeBy')}</FormLabel>
            <Controller
              as={<AsyncSelect />}
              options={onwerDescsOption ? onwerDescsOption : []}
              name="changeBy"
              isClearable
              isDisabled={isReadOnly || props.readOnly}
              control={props.control}
              rules={{ required: 'Required' }}
              defaultOptions={onwerDescsOption}
              loadOptions={(value) => {
                if (!values.destOwnerType) {
                  window.alert('please select destination Ownertype first');
                  return null;
                }
                return ownersQuery(values.destOwnerType.value, value);
              }}
              placeholder={
                values.destOwnerType.label
                  ? 'Search by ' + values.destOwnerType.label
                  : 'Select...'
              }
              onChange={(value) => {
                //console.log(value);
                handleSelectChange(value);
                return value[0];
              }}
            />

            {/* <Controller
              name="changeBy"
              as={<Select />}
              options={onwerDescsOption}
              isClearable
              control={props.control}
              onChange={(value) => {
                handleSelectChange(value);
                return value[0];
              }}
              rules={{ required: 'changeBy is Required' }}
              isDisabled={props.readOnly}
            /> */}
            <FormHelperText error>
              {!!props.errors.changeBy && <>{props.errors.changeBy.message}</>}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default translate(AssetOwnerChange_Header);
