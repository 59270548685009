import axios from 'axios';
import { BASE_URL } from '../url';
import { authHeader } from './auth-header';
import { store } from './store';
import { userAction } from '../_action/user.action';
import { alertActions } from '../_action/alert.action';

const instance = axios.create({
  // baseURL: BASE_URL,
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: 'https://api.assetuat.verismartsoft.biz',
  timeout: 10000,
  // headers: { Authorization: authHeader },
  //Authorization: authHeader,
  transformResponse: (data) => {
    let obj = JSON.parse(data);
    // if (obj.status === '400') {
    //   console.log('error response: ', obj);
    //   store.dispatch(alertActions.error(obj.message));
    // }
    return JSON.parse(data);
  },
});

// instance.defaults.headers.common['Authorization'] = authHeader;
// instance.defaults.timeout = 2500;
export const postJQGRid = () => async (url, parameters, data, authen) => {
  return await instance
    .post(url, data, {
      params: { ...parameters },
      headers: {
        Authorization: authen,
      },
      timeout: 5000,
    })
    .then((res) => {
      if (res.data.status === 400) {
        //console.log('logging out');
        store.dispatch(userAction.logout());
      }
      return res;
    })
    .catch((err) => {
      const stringError = JSON.stringify(err);
      return Promise.reject(JSON.parse(stringError));
    });
};

export const postRequestFormData = async (
  url,
  parameters,
  data,
  authen,
  type = 'application/json',
) => {
  return await instance
    .post(url, data, {
      params: { ...parameters },
      headers: {
        'content-type': type,
        Authorization: authen,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const stringError = JSON.stringify(err);
      return Promise.reject(JSON.parse(stringError));
    });
};

export const postRequest = async (
  url,
  parameters,
  data,
  authen,
  type = 'application/json',
) => {
  return await instance
    .post(url, data, {
      params: { ...parameters },
      headers: {
        'content-type': type,
        Authorization: authen,
      },
    })
    .then((res) => {
      if (res.data.status === 400) {
        console.log('logging out');
        store.dispatch(userAction.logout());
      }

      return res;
    })
    .catch((err) => {
      console.log('err: ', err);
      const stringError = JSON.stringify(err);
      return err;
    });
};

export const postRequestSync = async (
  url,
  parameters,
  data,
  authen,
  type = 'application/json',
) => {
  try {
    const res = await instance.post(url, data, {
      params: { ...parameters },
      headers: {
        'content-type': type,
        Authorization: authen,
      },
    });
    if (res.data.status === 400) {
      console.log('logging out');
      store.dispatch(userAction.logout());
    }
    return res;
  } catch (err) {
    const stringError = JSON.stringify(err);
    return Promise.reject(JSON.parse(stringError));
  }
};

export const getRequest = async (url, parameters, authen) => {
  return await instance
    .get(url, {
      params: { ...parameters },
      headers: {
        Authorization: authen,
      },
    })
    .then((res) => {
      if (res.data.status === 400) {
        console.log('logging out');
        store.dispatch(userAction.logout());
      }
      return res;
    })
    .catch((err) => {
      const stringError = JSON.stringify(err);
      return Promise.reject(JSON.parse(stringError));
    });
};

export const getRequestSync = async (url, parameters, authen) => {
  try {
    const res = await instance.get(url, {
      params: { ...parameters },
      headers: {
        Authorization: authen,
      },
    });
    return res;
  } catch (err) {
    const stringError = JSON.stringify(err);
    return Promise.reject(JSON.parse(stringError));
  }
};
export const httpClient_Post = async (url, data, authen) => {
  const config = {
    method: 'post',
    baseURL: BASE_URL,
    timeout: 10000,
    url: url,
    headers: {
      Authorization: authen,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return await axios(config)
    .then(function (response) {
      //console.log(response);
      // return JSON.stringify(response);
      return response;
    })
    .catch(function (error) {
      const stringError = {
        message: error.message,
      };
      return Promise.reject(stringError);
    });
};

export const httpClient_Post_with_Params = async (url, parameters, authen) => {
  var config = {
    baseURL: BASE_URL,
    timeout: 10000,
    method: 'post',
    url: url,
    headers: {
      Authorization: authen,
    },
    params: { ...parameters },
  };

  return await axios(config)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      const stringError = {
        message: error.message,
      };
      return Promise.reject(stringError);
    });
};

export const httpClient_borrow_approve = async (url, parameters, authen) => {
  var config = {
    baseURL: BASE_URL,
    timeout: 10000,
    method: 'post',
    url: url + '?code=' + parameters,
    headers: {
      Authorization: authen,
    },
  };
  return await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      const stringError = {
        message: error.message,
      };
      return Promise.reject(stringError);
    });
};

export const httpClient_borrow_delete = async (url, parameters, authen) => {
  var config = {
    baseURL: BASE_URL,
    timeout: 10000,
    method: 'post',
    url: url + '?code=' + parameters,
    headers: {
      Authorization: authen,
    },
  };
  return await axios(config)
    .then(function (response) {
      return 'OK';
    })
    .catch(function (error) {
      return 'NOK';
    });
};
