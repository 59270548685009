import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';

import { formType } from '../../../_constants';
import {
  InputText,
  InputSelect,
} from '../../../_fragments/formField/controllerFieldMUI';
import { useDispatch } from 'react-redux';
import FormModal from '../../../_fragments/_modal/FormModal';
import { formHeader } from '../../../_helpers/utility';
function Form({ t, type, code, open, header, setClose, useService, item }) {
  const [schema, actions, model, constants, site_constants] = useService();
  const dispatch = useDispatch();

  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, errors, control, reset } = useForm({
    validationSchema: schema,
  });

  const onSubmit = (data) =>
    new Promise((resolve, reject) => {
      try {
        switch (type) {
          case formType.UPDATE: {
            dispatch(actions.update(data));
            break;
          }
          case formType.DELETE: {
            dispatch(actions.remove({ code: data.code }));
            break;
          }
          case formType.INSERT: {
            dispatch(actions.insert(data));
            break;
          }
        }
        resolve(true);
      } catch (error) {
        reject('Error message: ', error);
      }
    });

  const onSubmitAsync = async (data) => {
    onSubmit(data)
      .then((res) => {
        if (res) {
          onClose();
        } else {
        }
      })
      .catch((err) => {
        console.log(('err', err));
      });
  };
  const onClose = (e = undefined) => {
    if (!!e) e.preventDefault();
    reset(model);
    setClose();
  };
  useEffect(() => {
    if (open) {
      if (type === formType.UPDATE) {
        dispatch(actions.get(code));
        setReadOnly(false);
      } else if (type === formType.INSERT) {
        reset(model);
        setReadOnly(false);
      } else if (type === formType.VIEW || type === formType.DELETE) {
        setReadOnly(true);
        dispatch(actions.get(code));
      }
    }
    if (!open) {
      reset(model);
    }
  }, [open]);

  useEffect(() => {
    reset(item);
  }, [item]);
  return (
    <>
      <FormModal
        open={open}
        onClose={setClose}
        header={formHeader(t, header.name, header.icon, type)}
        type={type}
        size="small"
        onSubmit={handleSubmit(onSubmitAsync)}
      >
        <InputText
          name="code"
          control={control}
          label="code"
          placeholder="code"
          error={
            !!errors.code && {
              content: errors.code.message,
            }
          }
          readOnly={readOnly || type === formType.UPDATE}
          required
        />
        <InputText
          name="description"
          control={control}
          label="description"
          placeholder="description"
          error={
            !!errors.description && {
              content: errors.description.message,
            }
          }
          readOnly={readOnly}
        />
        <InputSelect
          name="site"
          control={control}
          label="Site"
          error={
            !!errors.site && {
              content: errors.site.message,
            }
          }
          service={site_constants.SERVICE}
          readOnly={readOnly}
          required
        />
      </FormModal>
    </>
  );
}

export default translate(Form);
