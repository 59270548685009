import { assetConstants as constants } from '../_constants';
import { assetService as services } from '../_services/asset.service';
import { alertActions as alerts } from './alert.action';
import { alertConstants as message } from '../_constants';
export const assetAction = {
  insert,
  update,
  remove,
  get,
  getAll,
  reset,
  insertImage,
  updateImage,
  deleteImage,
  getImage,
};

function request(t) {
  return { type: t };
}
function success(t, res) {
  return { type: t, data: res };
}
function failure(t, error) {
  return { type: t, error };
}
function reset(t) {
  return { type: t };
}
function insert(data) {
  return (dispatch) => {
    dispatch(request(constants.INSERT_REQUEST));
    return services.insertItem(data).then(
      (res) => {
        dispatch(success(constants.INSERT_SUCCESS, res));
        dispatch(alerts.success(message.INSERT_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.INSERT_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function update(data) {
  return (dispatch) => {
    dispatch(request(constants.UPDATE_REQUEST));
    return services.updateItem(data).then(
      (res) => {
        dispatch(success(constants.UPDATE_SUCCESS, res));
        dispatch(alerts.success(message.UPDATE_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.UPDATE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function remove(data) {
  return (dispatch) => {
    dispatch(request(constants.DELETE_REQUEST));
    return services.deleteItem(data).then(
      (res) => {
        dispatch(success(constants.DELETE_SUCCESS, res));
        dispatch(alerts.success(message.DELETE_SUCCESS));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.DELETE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function get(code) {
  return (dispatch) => {
    dispatch(request(constants.GET_REQUEST));
    return services.getItem(code).then(
      (res) => {
        let obj = convert2Select(res.data.info);
        var converted = {
          ...res,
          data: {
            ...res.data,
            info: JSON.parse(JSON.stringify(obj)),
          },
        };
        dispatch(success(constants.GET_SUCCESS, converted));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.GET_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function getAll() {
  return (dispatch) => {
    dispatch(request(constants.GETALL_REQUEST));
    return services.getAllItem().then(
      (res) => {
        dispatch(success(constants.GETALL_SUCCESS, res));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.GETALL_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function insertImage(code, files) {
  return (dispatch) => {
    dispatch(request(constants.INSERT_IMAGE_REQUEST));
    return services.insertImage(code, files).then(
      (res) => {
        dispatch(success(constants.INSERT_IMAGE_SUCCESS, res));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.INSERT_IMAGE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function updateImage(position, code, file) {
  return (dispatch) => {
    dispatch(request(constants.UPDATE_IMAGE_REQUEST));
    return services.updateImage(position, code, file).then(
      (res) => {
        dispatch(success(constants.UPDATE_IMAGE_SUCCESS, res));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.UPDATE_IMAGE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function deleteImage(code) {
  return (dispatch) => {
    dispatch(request(constants.DELETE_IMAGE_REQUEST));
    return services.deleteImage(code).then(
      (res) => {
        dispatch(success(constants.DELETE_IMAGE_SUCCESS, res));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.DELETE_IMAGE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

function getImage(code) {
  return (dispatch) => {
    dispatch(request(constants.GET_IMAGE_REQUEST));
    return services.getImage(code).then(
      (res) => {
        dispatch(success(constants.GET_IMAGE_SUCCESS, res));
        dispatch(reset(constants.RESET));
        return res;
      },
      (error) => {
        dispatch(failure(constants.GET_IMAGE_FAILURE, error));
        dispatch(alerts.error(JSON.stringify(error.message)));
        return error;
      },
    );
  };
}

const convert2Object = (obj) => {
  let new_obj = {
    ...obj,

    AssetUnit:
      !!obj.AssetUnit && !!obj.AssetUnit.value ? obj.AssetUnit.value : '',
    AssetBrand:
      !!obj.AssetBrand && !!obj.AssetBrand.value ? obj.AssetBrand.value : '',
    AssetClass:
      !!obj.AssetClass && !!obj.AssetClass.value ? obj.AssetClass.value : '',
    AssetSubClass:
      !!obj.AssetSubClass && !!obj.AssetSubClass.value
        ? obj.AssetSubClass.value
        : '',
    AssetCondition:
      !!obj.AssetCondition && !!obj.AssetCondition.value
        ? obj.AssetCondition.value
        : '',
    AssetGroup:
      !!obj.AssetGroup && !!obj.AssetGroup.value ? obj.AssetGroup.value : '',
    AssetModel:
      !!obj.AssetModel && !!obj.AssetModel.value ? obj.AssetModel.value : '',

    AssetSupplier:
      !!obj.AssetSupplier && !!obj.AssetSupplier.value ? obj.v.value : '',

    DimensionUomId:
      !!obj.DimensionUomId && !!obj.DimensionUomId.value
        ? obj.DimensionUomId.value
        : '',
    WeightUomId:
      !!obj.WeightUomId && !!obj.WeightUomId.value ? obj.WeightUomId.value : '',

    CostCenter:
      !!obj.CostCenter && !!obj.CostCenter.value ? obj.CostCenter.value : '',

    // BusinessArea: !!obj.BusinessArea.value ? obj.BusinessArea.value : '',
    // Site: !!obj.Site.value ? obj.Site.value : '',
    // Location: !!obj.Location.value ? obj.Location.value : '',
    // Zone: !!obj.Zone.value ? obj.Zone.value : '',
  };
  return new_obj;
};
const convert2Select = (obj) => {
  let new_obj = {
    ...obj,
    AssetUnit: {
      value: obj.AssetUnit,
      label: obj.AssetUnit + ' - ' + obj.AssetUnitDescription,
    },
    AssetBrand: {
      value: obj.AssetBrand,
      label: obj.AssetBrand + ' - ' + obj.AssetBrandDescription,
    },
    AssetClass: {
      value: obj.AssetClass,
      label: obj.AssetClass + ' - ' + obj.AssetClassDescription,
    },
    AssetSubClass: {
      value: obj.AssetSubClass,
      label: obj.AssetSubClass + ' - ' + obj.AssetSubClassDescription,
    },
    AssetCondition: {
      value: obj.AssetCondition,
      label: obj.AssetCondition + ' - ' + obj.AssetConditionDescription,
    },
    AssetGroup: {
      value: obj.AssetGroup,
      label: obj.AssetGroup + ' - ' + obj.AssetGroupDescription,
    },
    AssetModel: {
      value: obj.AssetModel,
      label: obj.AssetModel + ' - ' + obj.AssetModelDescription,
    },

    AssetSupplier: {
      value: obj.AssetSupplier,
      label: obj.AssetSupplier + ' - ' + obj.AssetSupplierDescription,
    },

    DimensionUomId: {
      value: obj.DimensionUomId,
      label: obj.DimensionUomId + ' - ' + obj.dimensionUomDescription,
    },
    WeightUomId: {
      value: obj.WeightUomId,
      label: obj.WeightUomId + ' - ' + obj.weightUomDescription,
    },

    CostCenter: {
      value: obj.CostCenter,
      label: obj.CostCenter + ' - ' + obj.CostCenterDescription,
    },

    BusinessArea: {
      value: obj.BusinessArea,
      label: obj.BusinessArea + ' - ' + obj.BusinessAreaDescription,
    },
    Site: {
      value: obj.Site,
      label: obj.Site + ' - ' + obj.SiteDescription,
    },
    Location: {
      value: obj.Location,
      label: obj.Location + ' - ' + obj.LocationDescription,
    },
    Zone: {
      value: obj.Zone,
      label: obj.Zone + ' - ' + obj.ZoneDescription,
    },
  };
  return new_obj;
};
