import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { authentication } from './authentication.reducer';
import { jQGridListData } from './jqgrid.reducer';
import { assetSupplierOperation } from './assetSupplier.reducer';
import { businessAreaOperation } from './businessArea.reducer';
import { assetGroupOperation } from './assetGroup.reducer';
import { assetConditionOperation } from './assetCondition.reducer';
import { assetClassOperation } from './assetClass.reducer';
import { assetSubClassOperation } from './assetSubClass.reducer';
import { assetBrandOperation } from './assetBrand.reducer';
import { assetModelOperation } from './assetModel.reducer';
import { siteOperation } from './site.reducer';
import { locationOperation } from './location.reducer';
import { zoneOperation } from './zone.reducer';
import { assetOperation } from './asset.reducer';
import { deleteOperation } from './delete.reducer';
import { uomOperation } from './uom.reducer';
import { branchOperation } from './branch.reducer';
import { companyOperation } from './company.reducer';
import { ownerOperation } from './owner.reducer';
import { costCenterOperation } from './costCenter.reducer';
import { assetTranserStorage } from './transaction/asset.transfer.reducer';
import { default as borrowStore } from './../components/Invenotry/assetborrow/assetborrowinsert/components/redux/reducers/borrow.additem.reducer';
import { default as returnStore } from './../components/Invenotry/assetreturn/assetreturninsert/components/redux/reducers/return.additem.reducer';
import { default as assetSearch } from './../components/Invenotry/_InventoryFlagments/redux/reducers/assetsearch.reducer';
import { default as ownerSearch } from './../components/Invenotry/_InventoryFlagments/redux/reducers/ownersearch.reducer';
import { default as assetTable } from './../components/Invenotry/_InventoryFlagments/redux/reducers/assettable.reducer';
import { default as assetCheck } from './../components/Invenotry/_InventoryFlagments/redux/reducers/assetcheck.reducer';
import { roleOperation } from './role.reducer';
import { userOperation } from './user.reducer';
import { permissionOperation } from './permission.reducer';
import { default as customerStore } from './customer.reducer';
import { employeeOperation } from './employee.reducer';
import { departmentOperation } from './department.reducer';
const rootReducer = combineReducers({
  alert,
  authentication,
  jQGridListData,
  deleteOperation,
  assetSupplierOperation,
  businessAreaOperation,
  assetGroupOperation,
  assetConditionOperation,
  assetClassOperation,
  assetSubClassOperation,
  assetBrandOperation,
  assetModelOperation,
  siteOperation,
  locationOperation,
  zoneOperation,
  assetOperation,
  uomOperation,
  branchOperation,
  companyOperation,
  ownerOperation,
  assetTranserStorage,
  borrowStore,
  returnStore,
  roleOperation,
  userOperation,
  assetTable,
  permissionOperation,
  assetSearch,
  ownerSearch,
  costCenterOperation,
  assetCheck,
  customerStore,
  employeeOperation,
  departmentOperation,
});

export default rootReducer;
