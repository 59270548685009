import { jQGRidConstants } from '../_constants';
import { authHeader } from '../_helpers/auth-header';
import { postRequest, httpClient_Post } from '../_helpers';
import { Filter1Sharp } from '@material-ui/icons';

export const jQGridService = {
  search,
  searchAssetByOwner,
  searchAutocomplete,
  searchAdvance,
  search2,
  AdvanceSearch,
  searchAssetWithFilter,
};

async function searchAssetWithFilter(
  page,
  rowperpage,
  filters,
  searchInput = '',
  totalCount = 10,
) {
  return postRequest(
    jQGRidConstants.API_ASSET_ADV_SEARCH,
    null, // parameter
    {
      filters: changeFilterOperator(filters, 'like'),
      orderDirection: 'asc',
      page,
      pageSize: rowperpage,
      search: searchInput,
      totalCount: totalCount,
    },
    authHeader().Authorization,
  ).catch((err) => err);
}

// parameters oriented (for Location)
function search2(filename_config, page, rowperpage, searchInput = '') {
  var bodyFormData = new FormData();
  bodyFormData.set('req_search', searchInput);
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);

  return postRequest(
    jQGRidConstants.API,
    { filename_config, page, rowperpage }, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}

async function search(filename_config, page, rowperpage, searchInput = '') {
  var bodyFormData = new FormData();
  bodyFormData.set('req_search', searchInput);
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);
  //console.log('filename_config: ', filename_config);
  return await postRequest(
    jQGRidConstants.API,
    null, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}

async function searchAssetByOwner(
  filename_config,
  page,
  rowperpage,
  req_search = '',
  req_search2 = '',
  req_search3 = '',
) {
  var bodyFormData = new FormData();
  bodyFormData.set('req_search', req_search);
  bodyFormData.set('req_search2', req_search2);
  bodyFormData.set('req_search3', req_search3);
  bodyFormData.set('filename_config', filename_config);
  bodyFormData.set('page', page);
  bodyFormData.set('rowperpage', rowperpage);
  //console.log('filename_config: ', filename_config);
  return await postRequest(
    jQGRidConstants.API,
    null, // parameter
    bodyFormData,
    authHeader().Authorization,
  );
}

function searchAdvance(filename_config, page, rowperpage, filters) {
  var data = {
    filename_config,
    page,
    rowperpage,
    filters,
  };
  return postRequest(
    jQGRidConstants.API_ADVANCE,
    null, // parameter
    JSON.stringify(data),
    authHeader().Authorization,
  );
}

function searchAutocomplete(
  filename_config,
  recordTotal = 10,
  searchInput = '',
) {
  var bodyFormData = new FormData();

  bodyFormData.set('req_search', searchInput);
  return postRequest(
    jQGRidConstants.API_AUTOCOMPLETE, // url
    { filename_config, recordTotal }, // parameter
    bodyFormData, // body
    authHeader().Authorization, // authen
  );
}

async function AdvanceSearch(data) {
  return await httpClient_Post(
    jQGRidConstants.API_ASSET_ADV_SEARCH,
    data,
    authHeader().Authorization,
  )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

function changeFilterOperator(filter, op) {
  console.log('before filter: ', filter);
  var newFilter;
  newFilter = filter.map((e) => {
    return {
      ...e,
      operator: op,
    };
  });
  console.log('advance filter: ', newFilter);
  return newFilter;
}
