import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from 'react-switch-lang';
import { default as Header } from './assetownerchange.header';
import { default as Additem } from './assetownerchange.detail';
import { useSelector, useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { useAlert } from 'react-alert';
import {
  Grid,
  CardContent,
  Card,
  CardHeader,
  Button,
  Slide,
} from '@material-ui/core';
import { Title } from '../../../_fragments';
import { Save, Update, ResetItem } from './redux/actions/ownerchange.action';
import { OwnerChangeServices as service } from './services/asset.ownerchange.service';
import { MyAlert as Alert } from '../../../_fragments/alert';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    // height: 100,
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnArroved: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
}));

const AssetOwnerChangeForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { t, history, ...rest } = props;
  const {
    handleSubmit,
    errors,
    reset,
    control,
    register,
    setValue,
    getValues,
  } = useForm();

  //states
  let isReadOnly = false;
  let isCreate = false;
  if (!!props.location.state) {
    isReadOnly = props.location.state.isReadOnly;
    if (props.location.state.status == 'APPROVE') {
      isReadOnly = true;
    }
    if (props.location.state.action == 'create') {
      isCreate = true;
    }
  } else {
    isReadOnly = false;
  }

  const initialData = {
    code: '',
    changeDate: '',
    date: '',
    changeBy: '',
    sourceOwner: '',
    sourceOwnerType: '',
    destOwnerType: '',
    reason: '',
    remark: '',
    assetOwnerChangeDetail: [
      {
        assetCode: '',
        assetDescription: '',
        serial: '',
        quanitity: 0,
        remark: '',
      },
    ],
  };
  const [data, setData] = useState(initialData);
  const [submitting, setSubmitting] = useState(false);
  const storage = useSelector((state) => state.borrowStore);
  let myData = {
    code: '',
    changeDate: '',
    date: '',
    changeBy: '',
    sourceOwner: '',
    sourceOwnerType: '',
    destOwnerType: '',
    reason: '',
    remark: '',
    status: '',
    assetOwnerChangeDetail: [],
  };

  //useEffects
  useEffect(() => {
    //console.log('pros ', props.location.state.action);
  }, []);

  //Events
  const onsubmitAsync = async (formData) => {
    //console.log('formData', formData);

    const storageSelector = (Obj) => {
      return Object.keys(Obj).map((storageKey) => ({
        assetCode: Obj[storageKey].assetCode,
        serial: Obj[storageKey].serial,
        assetDescription: Obj[storageKey].assetDescription,
        quanitity: Obj[storageKey].quanitity,
        remark: Obj[storageKey].remark,
      }));
    };

    const storageArray = storageSelector(storage);
    console.log('storageArray', storageArray);
    if (storageArray.length == 0) {
      window.alert('Please add item before save');
      return;
    }
    myData = {
      code: formData.code,
      changeDate: formData.date,
      date: formData.date,
      changeBy: formData.changeBy.value,
      sourceOwner: formData.sourceOwner.value,
      sourceOwnerType: formData.sourceOwnerType.value,
      destOwnerType: formData.destOwnerType.value,
      reason: formData.reason.value,
      remark: formData.remark,
      status: 'OPEN',
      assetOwnerChangeDetail: storageArray,
    };
    // console.log('myData ', myData);
    // const confirmSave = window.confirm('confirm to save ?');

    Alert.Confirm('Do you confirm to save ?').then((res) => {
      if (res.value) {
        confirmEvent();
      }
    });

    const confirmEvent = async () => {
      if (true) {
        try {
          if (props.location.state.action == 'create') {
            console.log('created...');
            // console.log(myData);
            dispatch(Save(myData));
            dispatch(ResetItem);
            setSubmitting(false);
          }
          if (props.location.state.action == 'edit') {
            console.log('edit...');
            dispatch(Update(myData));
            dispatch(ResetItem);
            setSubmitting(false);
          }

          if (props.location.state.action == 'view') {
            console.log('view...');
            dispatch(ResetItem);
            setSubmitting(false);
          }
        } catch (error) {
          console.log('error-save', error);
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
      }
    };
  };

  const approveEvent = async () => {
    const result = await service.Approve(props.match.params.id);
    if (result === 'OK') {
      alert.show('Approved', {
        timeout: 2000,
        type: 'success',
      });
      history.goBack();
    } else {
      alert.error(result);
    }
  };

  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });

  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <form noValidate onSubmit={handleSubmit(onsubmitAsync)}>
          <Grid container className={classes.root} direction="column">
            <Grid item xs={12} md={12}>
              <Card className={classes.card} component="div" square>
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <>
                      <Grid container direction="row">
                        <Grid item xs={12} sm={7}>
                          <Title
                            icon="cubes"
                            iconaction="exchange"
                            content="Asset Owner Change"
                            description={
                              !!props.location.state.action
                                ? props.location.state.action
                                : ''
                            }
                          />
                        </Grid>

                        <Grid item style={{ textAlign: 'end' }} xs={12} sm={5}>
                          {!isReadOnly && (
                            <>
                              {!isCreate && (
                                <Button
                                  name="approveBtn"
                                  className={classes.btnArroved}
                                  size="large"
                                  color="secondary"
                                  variant="outlined"
                                  startIcon={<BeenhereIcon />}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    approveEvent();
                                  }}
                                >
                                  Approve
                                </Button>
                              )}

                              <Button
                                className={classes.btnSave}
                                variant="outlined"
                                type="submit"
                                size="large"
                                startIcon={<SaveIcon />}
                                name="submitBtn"
                              >
                                Save
                              </Button>
                            </>
                          )}

                          <Button
                            className={classes.btnBack}
                            name="backBtn"
                            size="large"
                            color="default"
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            onClick={(e) => {
                              e.preventDefault();
                              setDirection({
                                ...direction,
                                slide: 'left',
                                in: false,
                              });
                              setTimeout(() => {
                                history.goBack();
                              }, direction.timeout);
                            }}
                          >
                            Back
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  }
                ></CardHeader>
                <CardContent>
                  <Header
                    code={props.match.params.id}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    reset={reset}
                    readOnly={isReadOnly}
                  />

                  <Additem
                    code={props.match.params.id}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    reset={reset}
                    readOnly={isReadOnly}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Slide>
    </>
  );
};

export default translate(AssetOwnerChangeForm);
