import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { uomAction as uomActions } from '../../_action/uom.action';

// import { commonLabel } from '../../labelConstant';
import ReactSelect from 'react-select';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Typography,
  TextField,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { serviceMap } from '../../_services';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 0,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    padding: 0,
    margin: 0,
  },
});

export {
  InputDate,
  InputDateControlled2,
  InputDateControlled,
  InputNumber,
  InputNumberUncontrolled,
  InputText,
  InputTextUncontrolled,
  InputToggle,
  InputCheckbox,
  InputSelect,
  InputSelectControlled,
  InputSelectCascaded,
  InputTextAutocomplete,
  InputTextSearch,
  InputSelectControlledRedux,
  InputSelectManualOption,
};

const InputDateControlled2 = (props) => {
  const [selectedDate, handleDateChange] = useState(new Date());
  const { name, value, onChange, disabled } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        name={name}
        clearable
        format="dd/MM/yyyy"
        value={value}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => handleDateChange(null)}>
              <ClearIcon />
            </IconButton>
          ),
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        disabled={disabled}
        color="primary"
        size="small"
        variant="outlined"
        fullWidth
      />
    </MuiPickersUtilsProvider>
  );
};
const InputDateControlled = (props) => {
  const { name, value, onChange, disabled } = props;
  return (
    <>
      <TextField
        name={name}
        type="date"
        color="primary"
        size="small"
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </>
  );
};
const InputDate = translate((props) => {
  const { t } = props;

  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={props.required}
        error={!!props.error}
        size="small"
      >
        <FormLabel component="legend">{t(props.label)}</FormLabel>
        <Controller
          name={props.name}
          control={props.control}
          type="date"
          as={<TextField />}
          control={props.control}
          color="primary"
          error={!!props.error}
          helperText={props.error.content}
          size="small"
          variant="outlined"
          fullWidth
          onChange={props.onChange}
          disabled={props.readOnly}
          defaultValue={props.defaultValue}
          // value={props.value}
          // required={props.required}
          rules={{ required: props.required }}
        />
        <FormHelperText>{!!props.error && 'required'}</FormHelperText>
      </FormControl>
    </>
  );
});

const InputNumberUncontrolled = translate((props) => {
  const { t } = props;
  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={props.required}
        size="small"
      >
        <FormLabel component="legend">{t(props.label)}</FormLabel>
        <Controller
          name={props.name}
          control={props.control}
          type="number"
          placeholder={t(props.placeholder)}
          as={TextField}
          color="primary"
          error={!!props.error}
          helperText={props.error.content}
          size="small"
          variant="outlined"
          defaultValue={''}
          fullWidth
          disabled={props.readOnly}
        />
        <FormHelperText>{!!props.error && props.error.content}</FormHelperText>
      </FormControl>
    </>
  );
});
const InputNumber = translate((props) => {
  const { t } = props;
  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={props.required}
        error={!!props.error}
        size="small"
      >
        <FormLabel component="legend">{t(props.label)}</FormLabel>
        <Controller
          name={props.name}
          control={props.control}
          inputProps={{ min: 1 }}
          type="number"
          placeholder={t(props.placeholder)}
          as={TextField}
          color="primary"
          error={!!props.error}
          helperText={props.error.content}
          size="small"
          variant="outlined"
          defaultValue={props.defaultValue}
          fullWidth
          onChange={props.onChange}
          value={props.value}
          disabled={props.readOnly}
        />
        <FormHelperText>{!!props.error && props.error.content}</FormHelperText>
      </FormControl>
    </>
  );
});

const InputTextUncontrolled = translate((props) => {
  const { t } = props;
  const classes = useStyles();
  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={props.required}
        error={!!props.error}
        size="small"
      >
        <FormLabel component="legend">{t(props.label)}</FormLabel>

        <Controller
          name={props.name}
          control={props.control}
          type="text"
          placeholder={t(props.placeholder)}
          as={TextField}
          control={props.control}
          color="primary"
          error={!!props.error}
          size="small"
          variant="outlined"
          fullWidth
          disabled={props.readOnly}
          // defaultValue={''}
          // required={props.required}
          rules={{ required: props.required }}
        />

        {/* <FormHelperText>{!!props.error && props.error.content}</FormHelperText> */}
        <FormHelperText>{!!props.error && 'Required'}</FormHelperText>
      </FormControl>
    </>
  );
});
const InputText = translate((props) => {
  const [value, setvalue] = useState('');

  const { t } = props;

  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={props.required}
        error={!!props.error}
        size="small"
      >
        <FormLabel component="legend">{t(props.label)}</FormLabel>

        <Controller
          name={props.name}
          control={props.control}
          placeholder={t(props.placeholder)}
          as={<TextField />}
          control={props.control}
          color="primary"
          error={!!props.error}
          onChange={props.onChange}
          value={props.value}
          size="small"
          variant="outlined"
          fullWidth
          disabled={props.readOnly}
          defaultValue={props.defaultValue}
          //required={props.required}
          rules={{ required: props.required }}
        />

        <FormHelperText>{!!props.error && 'Required'}</FormHelperText>
      </FormControl>
    </>
  );
});

const InputToggle = translate((props) => {
  const { t } = props;
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        label={<Typography color="textPrimary">{t(props.label)}</Typography>}
        as={Checkbox}
        toggle
        valueName="checked"
        onChange={([_, data]) => data.checked}
        readOnly={props.readOnly}
      />
    </>
  );
});

const InputCheckbox = translate((props) => {
  const { t } = props;
  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        label={<Typography color="textPrimary">{t(props.label)}</Typography>}
        as={Checkbox}
        valueName="checked"
        onChange={([_, data]) => data.checked}
        readOnly={props.readOnly}
      />
    </>
  );
});

const InputSelectCascaded = (props) => {
  const { name, onChange, options } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      <ReactSelect
        name={name}
        onChange={onChange}
        isClearable
        options={options}
        isLoading={loading}
      />
    </>
  );
};

const InputSelectManualOption = (props) => {
  const { name, value, onChange, options } = props;

  return (
    <>
      <Select
        name={name}
        onChange={!!onChange && onChange}
        isClearable
        getOptionLabel={(op) => op.code + ' - ' + op.description}
        getOptionValue={(op) => op.code}
        options={options}
        value={value}
        menuPortalTarget={document.body}
      />
    </>
  );
};
const InputSelectControlledRedux = (props) => {
  const { name, value, onChange, action, reducer } = props;
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      if (reducer.getAllRequest) setLoading(true);
      else if (reducer.getAllSuccess) {
        setLoading(false);
        setOptions(reducer.data);
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [reducer]);
  useEffect(() => {
    var isCancelled = false;
    setLoading(true);
    if (!isCancelled) {
      dispatch(action.getAll());
    }
    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <>
      <Select
        isLoading={loading}
        name={name}
        onChange={!!onChange && onChange}
        isClearable
        getOptionLabel={(op) => op.code + ' - ' + op.description}
        getOptionValue={(op) => op.code}
        options={options}
        value={value}
        menuPortalTarget={document.body}
      />
    </>
  );
};
const InputSelectControlled = (props) => {
  const { name, value, onChange, service } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    var isCancelled = false;
    setLoading(true);
    if (!isCancelled) {
      serviceMap(service)
        .getAllItem()
        .then(
          (res) => {
            if (!!res.data.results) setOptions(res.data.results);
            setLoading(false);
          },
          (error) => {
            return error;
          },
        );
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <Select
        name={name}
        onChange={!!onChange && onChange}
        isClearable
        getOptionLabel={(op) => op.code + ' - ' + op.description}
        getOptionValue={(op) => op.code}
        options={options}
        value={value}
        menuPortalTarget={document.body}
      />
    </>
  );
};
const InputSelect = (props) => {
  const {
    // t,
    name,
    control,
    label,
    error,
    readOnly,
    onChange,
    service,
    filter,
    required,
  } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    serviceMap(service)
      .getAllItem()
      .then(
        (res) => {
          if (!!res.data.results) setOptions(res.data.results);
          setLoading(false);
        },
        (error) => {
          return error;
        },
      );
  }, []);

  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={required}
        error={!!error}
        size="small"
      >
        <FormLabel error={!!error} component="legend">
          {label}
        </FormLabel>
        <Controller
          as={ReactSelect}
          rules={{ required: required }}
          options={options
            .filter(!!filter ? filter : (el) => el)
            .map((o, i) => {
              return { value: o.code, label: o.code + ' - ' + o.description };
            })}
          name={name}
          onChange={!!onChange && onChange}
          isClearable
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          control={control}
          isDisabled={readOnly}
          isLoading={loading}
          placeholder={!!readOnly ? readOnly && '-' : 'Select...'}
        />
        <FormHelperText>{!!error && 'Required'}</FormHelperText>
      </FormControl>
    </>
  );
};

const InputTextAutocomplete = translate((props) => {
  const { t } = props;
  const classes = useStyles();
  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={props.required}
        error={!!props.error}
        size="small"
      >
        <FormLabel error={!!props.error} component="legend">
          {t(props.label)}
        </FormLabel>
        <Autocomplete
          freeSolo
          disableClearable
          value={props.value}
          onChange={props.onChange}
          inputValue={props.inputValue}
          onInputChange={props.onInputChange}
          options={props.options}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
        <FormHelperText>{!!props.error && 'Required'}</FormHelperText>
      </FormControl>
    </>
  );
});

const InputTextSearch = translate((props) => {
  const { t } = props;

  return (
    <>
      <FormControl
        margin="dense"
        fullWidth
        required={props.required}
        error={!!props.error}
        size="small"
      >
        <FormLabel component="legend">{t(props.label)}</FormLabel>

        <Controller
          name={props.name}
          control={props.control}
          type="text"
          placeholder={t(props.placeholder)}
          as={<TextField />}
          control={props.control}
          color="primary"
          error={!!props.error}
          onChange={props.onChange}
          onClick={props.onClick}
          value={props.value}
          size="small"
          variant="outlined"
          fullWidth
          disabled={props.readOnly}
          defaultValue={props.defaultValue}
          InputProps={{
            endAdornment: (
              <IconButton color="primary" aria-label="search" component="span">
                <SearchIcon />
              </IconButton>
            ),
          }}
          // required={props.required}
          rules={{ required: props.required }}
        />
        <FormHelperText>{!!props.error && 'Required'}</FormHelperText>
      </FormControl>
    </>
  );
});
