import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
export default function AssetCountReport() {
  const permissions = localStorage.getItem('user');
  const permissionsObj = JSON.parse(permissions);
  const [reportName, setReportName] = useState(null);
  const [isShowReport, setIsShowReport] = useState(false);
  const [errMsg, seterrMsg] = useState('');
  useEffect(() => {
    try {
      console.log('menu:', permissionsObj);
      const menu = permissionsObj.menus[1];
      const data = menu.children.find((i) => i.menuCode == 'M_ASSET_CHECK_RP');
      //  console.log(data.url_report);
      setReportName(data ? data.url_report : '');
      setIsShowReport(true);
    } catch (error) {
      setIsShowReport(false);
      seterrMsg(error);
      setReportName(null);
    }
  }, []);
  return (
    <>
      {isShowReport ? (
        <Iframe
          url={reportName}
          width="100%"
          height={window.innerHeight}
          id="myId"
          className="myClassname"
          display="block"
          position="static"
        />
      ) : (
        <>
          <h1>No Found report</h1>
          <h3>{('Error: ', errMsg)}</h3>
        </>
      )}
    </>
  );
}

// import React from 'react';
// import ReportTemplate from './report.template';
// const AssetReturnReport = () => {
//   const title = 'Asset Return Report';
//   const filenameXML = 'Return-List-Report';

//   const columns = [
//     { title: 'documentCode', field: 'documentCode' },
//     { title: 'documentDate', field: 'documentDate' },
//     { title: 'borrowDate', field: 'borrowDate' },
//     { title: 'borrowBy.', field: 'borrowBy' },
//     { title: 'returnCode', field: 'returnCode' },
//     { title: 'assetCode', field: 'assetCode' },
//     { title: 'assetDescription', field: 'assetDescription' },
//     { title: 'remark', field: 'remark' },
//     { title: 'createBy', field: 'createBy' },
//     { title: 'createDate', field: 'createDate' },
//   ];
//   return (
//     <>
//       <ReportTemplate
//         title={title}
//         columns={columns}
//         filenameXML={filenameXML}
//       />
//     </>
//   );
// };

// export default AssetReturnReport;
