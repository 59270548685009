import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Card,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core';
import HistoryDatatable from '../../../_fragments/ListingDatatable/HistoryDatatable/HistoryDatatable';
import { filenameXML } from '../../../_constants';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(1),
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  container: { padding: '0px !important' },
  group: { padding: theme.spacing(1), margin: theme.spacing(0) },
  card: { padding: theme.spacing(2), margin: theme.spacing(1) },
}));

const History = (props) => {
  const { t } = props;
  const classes = useStyles();
  const [formType, setFormType] = useState('');
  const header = { name: 'Asset', icon: 'cubes' };
  const menuCode = 'M_ASSET';

  const [title, setTitle] = useState({
    iconaction: 'add',
    content: 'Asset',
    description: 'History',
  });
  const columns = [
    { title: t('CREATEDATE'), field: 'CREATEDATE' },
    { title: t('DOCUMENTCODE'), field: 'DOCUMENTCODE' },
    { title: t('DOCUMENTNAME'), field: 'DOCUMENTNAME' },
    { title: t('ASSETCODE'), field: 'ASSETCODE' },
    { title: t('SERIALCODE'), field: 'SERIALCODE' },

    // { title: t('OWNERCODE'), field: 'OWNERCODE' },
    // { title: t('OWNERNAME'), field: 'OWNERNAME' },
    // { title: t('OWNERGROUPNAME'), field: 'OWNERGROUPNAME' },

    { title: t('OWNER'), field: 'OWNER' },
  ];
  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });
  useEffect(() => {
    let isCancelled = false;
    var state = props.location.state;
    setFormType(state);
    return () => {
      isCancelled = true;
      setFormType({});
    };
  }, []);
  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <HistoryDatatable
          header={header}
          columns={columns}
          filenameXML={filenameXML.asset_history}
          title="History"
          menuCode={menuCode}
          code={formType.code}
        />
      </Slide>
    </>
  );
};

export default translate(History);
