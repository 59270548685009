import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { ListingDatatable } from '../../_fragments';
import Form from './Form';
import { userSchema as schema } from '../../_validationSchema';
import { userAction as actions } from '../../_action/user.action';
import { user_model as model } from '../../_model';
import {
  userConstants as constants,
  roleConstants as role_constant,
} from '../../_constants';
import { useSelector } from 'react-redux';

const User = (props) => {
  const { t } = props;

  const header = { name: 'User', icon: 'cubes' };
  const reducer = useSelector((state) => state.userOperation);

  const filenameXML = 'user';
  const menuCode = 'M_USER';
  const columns = [
    { title: t('code'), field: 'Code' },
    { title: t('username'), field: 'Username' },
    { title: t('firstName'), field: 'FirstName' },
    { title: t('LastName'), field: 'LastName' },
  ];
  let useService = () => {
    return [schema, actions, model, role_constant];
  };
  const [open, setOpen] = useState(false);
  const [selectedCode, setSelected] = useState('');
  const [type, setFormType] = useState('');
  const [keyTrigger, setTrigger] = useState(false);
  useEffect(() => {
    if (open === false) {
      setFormType('');
      setTrigger(!keyTrigger);
    }
  }, [open]);

  return (
    <>
      <Form
        type={type}
        header={header}
        code={selectedCode}
        open={open}
        setClose={() => setOpen(false)}
        useService={useService}
        reducer={reducer}
      />
      <ListingDatatable
        key={keyTrigger}
        header={header}
        columns={columns}
        filenameXML={filenameXML}
        setSelected={setSelected}
        setFormOpen={setOpen}
        setFormType={setFormType}
        menuCode={menuCode}
      />
    </>
  );
};

export default translate(User);
