import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { translate } from 'react-switch-lang';
import { Header, Icon } from 'semantic-ui-react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import {
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  TextField,
  Grid,
  Slide,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { MyAlert as Alert } from '../../_fragments/alert';
import * as action from '../../_action/customer.action';
import * as service from '../../_services/owner.service';
import { OwnerChangeConstants } from '../../_constants/transaction';

//module

function CustomerForm(props) {
  const { t, history } = props;

  const classes = useStyles();
  const { handleSubmit, errors, register, control, setValue } = useForm();
  const dispatch = useDispatch(); //redux
  const customerInit = {
    code: '',
    branchcode: '',
    ref: '',
    name: '',
    othername: '',
    address1: '',
    address2: '',
    address3: '',
    postcode: '',
    remark: '',
  };
  //useStates
  const [customer, setCustomer] = useState(customerInit);
  const [direction, setDirection] = useState({
    slide: 'left',
    in: true,
    timeout: 650,
  });
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [owners, setOwners] = useState([]);
  const [selector, setSelector] = useState(null);
  const customerStore = useSelector((state) => state.customerStore);

  useEffect(() => {
    //Create
    if (props.location.state.action == 'create') {
      dispatch(action.ClearData());
      setCustomer(customerInit);
      ownersQuery(2, '');
    }
    // if (props.match.params.id != null) {
    if (props.location.state.action == 'view') {
      if (props.match.params.id != null) {
        const code = props.match.params.id;
        dispatch(action.GetCustomer(code));
        setIsReadOnly(true);
        ownersQuery(2, '');
      }
    }
    if (props.location.state.action == 'edit') {
      if (props.match.params.id != null) {
        const code = props.match.params.id;
        dispatch(action.GetCustomer(code));
        ownersQuery(2, '');
      }
    }
  }, []);

  //return customer
  useEffect(() => {
    if (props.location.state.action == 'view') {
      getCustomer();
    }
    if (props.location.state.action == 'edit') {
      getCustomer();
    }
  }, [customerStore]);

  //Handles
  const handleChange = (event) => {
    //console.log(event.target.value);
    setCustomer({
      ...customer,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    if (event[0] != null) {
      setCustomer({
        ...customer,
        [event[1].name]: event[0].value,
      });
    } else {
      setCustomer({
        ...customer,
        [event[1].name]: '',
      });
    }
  };

  //functions
  const getCustomer = async () => {
    const res = await customerStore.data;
    // console.log(res);
    if (!!res) {
      setCustomer({
        ...customer,
        code: res.code,
        name: res.name,
        othername: res.otherName,
        branchcode: res.branch,
        ref: res.ref,
        address1: res.address1,
        address2: res.address2,
        address3: res.address3,
        postcode: res.postcode,
        remark: res.remark,
      });
      const defaultSelect = await owners.find(
        (data) => data.value == res.branch,
      );
      setValue('branchcode', defaultSelect);
    } else {
      setCustomer(customerInit);
    }
  };

  const ownersQuery = async (ownerType, inputValue) => {
    try {
      const res = await service.ownerService.getOwnersAutocomplete({
        req_type: ownerType,
        req_search: inputValue,
        recordTotal: '30',
      });

      const resData = Object.keys(res).map(
        (key) =>
          new Object({
            value: res[key].value,
            label: res[key].label,
          }),
      );
      setOwners(resData);
    } catch (error) {
      console.log('error ownerQuery:', error);
    }
  };

  const onsubmitAsync = async (data) => {
    //
    Alert.Confirm('Do you confirm to save ?').then((res) => {
      if (res.value) {
        SubmitData(data);
      }
    });
  };

  const SubmitData = async (data) => {
    if (true) {
      try {
        if (props.location.state.action == 'create') {
          console.log(customer);
          dispatch(action.Save(customer));
        }
        if (props.location.state.action == 'edit') {
          // console.log('Edit...');
          console.log('Update', customer);
          dispatch(action.Update(customer));
        }

        if (props.location.state.action == 'view') {
          console.log('view...');
          alert('View');
        }
      } catch (error) {
        console.log('error-submitFunction', JSON.stringify(error));
        // setSubmitting(false);
      }
    } else {
      //   setSubmitting(false);
    }
  };

  return (
    <>
      <Slide
        timeout={direction.timeout}
        direction={direction.slide}
        in={direction.in}
        mountOnEnter
        unmountOnExit
      >
        <form
          key="formAssetBorrowInsert"
          onSubmit={handleSubmit(onsubmitAsync)}
          size="small"
          noValidate
          //loading={submitting}
          //success={props.success}
        >
          <Grid container className={classes.root}>
            <Grid item className={classes.griditem} xs={12} md={12}>
              <Grid container>
                <Grid item xs={12} md={7}>
                  <Header className={classes.bg}>
                    <Typography variant="h4" color="textPrimary">
                      <Icon name="user" />
                      {t('Customer informantion')}
                    </Typography>
                  </Header>
                </Grid>
                <Grid item style={{ textAlign: 'end' }} xs={12} sm={5}>
                  {!isReadOnly && (
                    <>
                      <Button
                        className={classes.btnSave}
                        variant="outlined"
                        type="submit"
                        size="large"
                        startIcon={<SaveIcon />}
                        name="submitBtn"
                      >
                        Save
                      </Button>
                    </>
                  )}

                  <Button
                    className={classes.btnBack}
                    name="backBtn"
                    size="large"
                    color="default"
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      setDirection({
                        ...direction,
                        slide: 'left',
                        in: false,
                      });
                      setTimeout(() => {
                        history.goBack();
                      }, direction.timeout);
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={4} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                    required
                  >
                    <FormLabel component="legend">{t('Code')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="code"
                      value={customer.code}
                      inputRef={register({ required: true })}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.code?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                    required
                  >
                    <FormLabel component="legend">{t('Name')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="name"
                      value={customer.name}
                      inputRef={register({ required: true })}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.name?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                  >
                    <FormLabel component="legend">{t('Othername')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="othername"
                      value={customer.othername}
                      inputRef={register()}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText>
                      {errors.othername?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12} className={classes.control}>
                  {/* //*new* */}
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                    required
                  >
                    <FormLabel component="legend">{t('Branch')}</FormLabel>
                    <Controller
                      name="branchcode"
                      as={<Select />}
                      options={owners}
                      isClearable
                      control={control}
                      rules={{ required: 'Branch is Required' }}
                      isDisabled={isReadOnly}
                      onChange={(value) => {
                        handleSelectChange(value);
                        return value[0];
                      }}
                    />

                    <FormHelperText error>
                      {!!errors.branchcode && <>{errors.branchcode.message}</>}
                    </FormHelperText>
                  </FormControl>

                  {/* <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                    required
                  >
                    <FormLabel component="legend">{t('Branch')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="branchcode"
                      value={customer.branchcode}
                      inputRef={register({ required: true })}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.branchcode?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl> */}
                </Grid>
                <Grid item md={8} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                  >
                    <FormLabel component="legend">{t('Ref')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="ref"
                      value={customer.ref}
                      inputRef={register()}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText>
                      {errors.ref?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                    required
                  >
                    <FormLabel component="legend">{t('Address1')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="address1"
                      value={customer.address1}
                      inputRef={register({ required: true })}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {errors.address1?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                  >
                    <FormLabel component="legend">{t('Address2')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="address2"
                      value={customer.address2}
                      inputRef={register()}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText>
                      {errors.address2?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                  >
                    <FormLabel component="legend">{t('Address3')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="address3"
                      value={customer.address3}
                      inputRef={register()}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText>
                      {errors.address3?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                  >
                    <FormLabel component="legend">{t('Postcode')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="postcode"
                      value={customer.postcode}
                      inputRef={register()}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText>
                      {errors.postcode?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={3} xs={12} className={classes.control}>
                  <FormControl
                    className={classes.input}
                    fullWidth
                    margin="dense"
                  >
                    <FormLabel component="legend">{t('Remark')}</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="remark"
                      value={customer.remark}
                      inputRef={register()}
                      onChange={handleChange}
                      disabled={isReadOnly}
                    />
                    <FormHelperText>
                      {errors.remark?.type === 'required' &&
                        'Your input is required'}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Slide>
    </>
  );
}

export default translate(CustomerForm);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  bg: {
    // background: 'linear-gradient(45deg, #b2dfdb 30%, #1976d2 90%)',
    padding: theme.spacing(3, 3, 0),

    //background: '#bbdefb',
  },
  bgBodyCard: {
    background: '#cfd8dc',
  },
  button: {
    margin: theme.spacing(1),
  },
  btnSave: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnArroved: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  btnBack: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  input: {
    padding: theme.spacing(1),
  },
}));
