import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const materialAction = (icon, tooltip, onClick) => {
  return {
    icon,
    tooltip,
    onClick,
  };
};
const addAction = (onClick) => {
  let actionObj = materialAction(
    'add', // icon
    'add', // tooltip
    onClick,
  );
  return {
    ...actionObj,
    isFreeAction: true,
  };
};

const viewAction = (onClick) => {
  return materialAction(
    () => <VisibilityIcon />, // icon
    'view', // tooltip
    onClick,
  );
};

const editAction = (onClick) => {
  return materialAction(
    () => <EditIcon />, // icon
    'edit', // tooltip
    onClick,
  );
};

const deleteAction = (onClick) => {
  return materialAction(
    () => <DeleteIcon />, // icon
    'edit', // tooltip
    onClick,
  );
};
const materialTableActions = {
  addAction,
  viewAction,
  editAction,
  deleteAction,
};

export default materialTableActions;
