export const employeeConstants = {
  API_INSERT: '/api/employee/insert',
  API_UPDATE: '/api/employee/update',
  API_DELETE: '/api/employee/delete',
  API_GET: '/api/employee/',
  API_GETALL: '/api/employee/getall',

  INSERT_REQUEST: 'EMPLOYEE_INSERT_REQUEST',
  INSERT_SUCCESS: 'EMPLOYEE_INSERT_SUCCESS',
  INSERT_FAILURE: 'EMPLOYEE_INSERT_FAILURE',

  UPDATE_REQUEST: 'EMPLOYEE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'EMPLOYEE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'EMPLOYEE_UPDATE_FAILURE',

  DELETE_REQUEST: 'EMPLOYEE_DELETE_REQUEST',
  DELETE_SUCCESS: 'EMPLOYEE_DELETE_SUCCESS',
  DELETE_FAILURE: 'EMPLOYEE_DELETE_FAILURE',

  GET_REQUEST: 'EMPLOYEE_GET_REQUEST',
  GET_SUCCESS: 'EMPLOYEE_GET_SUCCESS',
  GET_FAILURE: 'EMPLOYEE_GET_FAILURE',

  GETALL_REQUEST: 'EMPLOYEE_GETALL_REQUEST',
  GETALL_SUCCESS: 'EMPLOYEE_GETALL_SUCCESS',
  GETALL_FAILURE: 'EMPLOYEE_GETALL_FAILURE',

  RESET: 'EMPLOYEE_RESET',
  SERVICE: 'EMPLOYEE_SERVICE',
};
