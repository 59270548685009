import React, { useEffect, useState } from 'react';
import { translate } from 'react-switch-lang';
import { assetSchema as schema } from '../../_validationSchema';
import { assetAction as actions } from '../../_action/asset.action';
import { asset_model as model } from '../../_model';
import { assetConstants as constants, formType } from '../../_constants';
import { useSelector, useDispatch } from 'react-redux';
import { filenameXML } from '../../_constants';
import AdvancedDatatable from '../../_fragments/ListingDatatable/AdvanceDatatable';

import { Redirect } from 'react-router-dom';

const Asset = (props) => {
  const { t } = props;
  const header = { name: 'Asset', icon: 'cubes' };
  const reducer = useSelector((state) => state.assetOperation);
  const [item, setItem] = React.useState({});
  const [selectedCode, setSelected] = useState('');
  const [type, setFormType] = useState('');
  const dispatch = useDispatch();
  const menuCode = 'M_ASSET';
  const columns = [
    { title: t('code'), field: 'Code' },
    { title: t('serial'), field: 'Serial' },
    { title: t('description'), field: 'Description' },
    { title: t('brand'), field: 'AssetBrand' },

    { title: t('model'), field: 'AssetModel' },
    { title: t('group'), field: 'AssetGroup' },
  ];

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      if (reducer.getSuccess) setItem(reducer.data.data.info);
    }

    return () => {
      isCancelled = true;
    };
  }, [reducer]);
  useEffect(() => {
    var isCancelled = false;

    if (reducer.DELETE_SUCCESS) {
      setSelected('');
    }
    return () => {
      isCancelled = true;
    };
  }, [reducer]);
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      if (type === formType.DELETE) {
        console.log('remove: ', selectedCode);
        dispatch(actions.remove({ code: selectedCode }));
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [type]);
  return (
    <>
      {selectedCode != '' && type === formType.VIEW && (
        <Redirect
          to={{
            pathname: props.history.location.pathname + '/view',
            state: {
              code: selectedCode,
              form: type,
              root: props.history.location.pathname,
            },
          }}
        />
      )}
      {selectedCode != '' && type === formType.UPDATE && (
        <Redirect
          to={{
            pathname: props.history.location.pathname + '/update',
            state: {
              code: selectedCode,
              form: type,
              root: props.history.location.pathname,
            },
          }}
        />
      )}
      {type === 'insert_form' && (
        <Redirect
          to={{
            pathname: props.history.location.pathname + '/insert',
            state: {
              code: '',
              form: type,
              root: props.history.location.pathname,
            },
          }}
        />
      )}

      <AdvancedDatatable
        key={selectedCode}
        title="Asset"
        menuCode={menuCode}
        header={header}
        columns={columns}
        filenameXML={filenameXML.asset}
        setSelected={setSelected}
        setFormType={setFormType}
      />
    </>
  );
};

export default translate(Asset);
