import React, { useState, useEffect } from 'react';
import { translate } from 'react-switch-lang';
import { useForm } from 'react-hook-form';

import { formType } from '../../../_constants';
import {
  InputText,
  InputSelect,
} from '../../../_fragments/formField/controllerFieldMUI';
import { useDispatch } from 'react-redux';
import FormModal from '../../../_fragments/_modal/FormModal';
import { formHeader } from '../../../_helpers/utility';
function Form({ t, type, code, open, header, setClose, useService, reducer }) {
  const [schema, actions, model, location_constant] = useService();
  const dispatch = useDispatch();

  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const onClose = (e = undefined) => {
    if (!!e) e.preventDefault();
    reset(model);
    setClose();
  };
  const onSubmit = (data) => {
    switch (type) {
      case formType.UPDATE: {
        dispatch(actions.update(data));
        return;
      }
      case formType.DELETE: {
        dispatch(actions.remove({ code: data.code }));
        return;
      }
      case formType.INSERT: {
        dispatch(actions.insert(data));
        return;
      }
    }
  };
  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues: model,
    validationSchema: schema,
  });
  useEffect(() => {
    if (open) {
      if (type === formType.UPDATE) {
        dispatch(actions.get(code));
        setReadOnly(false);
      } else if (type === formType.INSERT) {
        reset(model);
        setReadOnly(false);
      } else if (type === formType.VIEW || type === formType.DELETE) {
        setReadOnly(true);
        dispatch(actions.get(code));
      }
    }
    if (!open) {
      reset(model);
    }
  }, [open]);

  useEffect(() => {
    if (reducer.getSuccess) {
      reset(reducer.data.data.info);
    }
    if (
      reducer.insertSuccess ||
      reducer.updateSuccess ||
      reducer.deleteSuccess
    ) {
      onClose();
      reset(model);
    }
    if (reducer.request) {
      setLoading(true);
    } else setLoading(false);
  }, [reducer]);

  return (
    <>
      <FormModal
        open={open}
        onClose={setClose}
        header={formHeader(t, header.name, header.icon, type)}
        type={type}
        size="small"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputText
          name="code"
          control={control}
          label="code"
          placeholder="code"
          error={
            !!errors.code && {
              content: errors.code.message,
            }
          }
          readOnly={readOnly || type === formType.UPDATE}
          required
        />
        <InputText
          name="zone"
          control={control}
          label="description"
          placeholder="description"
          error={
            !!errors.description && {
              content: errors.description.message,
            }
          }
          readOnly={readOnly}
        />

        <InputSelect
          name="location"
          control={control}
          label="Locaiton"
          error={
            !!errors.location && {
              content: errors.location.message,
            }
          }
          service={location_constant.SERVICE}
          readOnly={readOnly}
          required
        />
      </FormModal>
    </>
  );
}

export default translate(Form);
